import React from "react";

type Props = {
  stats: PercentageType | OutOfType;
  label?: string;
};

type PercentageType = {
  percentage: number;
};

type OutOfType = {
  completed: number;
  total: number;
};

const ProgressBar = ({ label, stats }: Props) => {
  const percentage =
    "percentage" in stats
      ? Math.round(stats.percentage)
      : Math.round((stats.completed / stats.total) * 100);

  const statsString =
    "percentage" in stats ? (
      <p className="desc-font shrink-0 font-semibold text-stone-600">
        {stats.percentage}%
      </p>
    ) : (
      <p className="desc-font shrink-0 text-stone-600">
        {stats.completed}/{stats.total}{" "}
        {`file${stats.total !== 1 ? "s" : ""}
        uploaded`}
      </p>
    );

  return (
    <div className="my-2 w-full">
      <div className="mb-1 flex items-end justify-between gap-4">
        <p className="body-font line-clamp-1 break-all">{label}</p>
        {statsString}
      </div>

      <div className="relative h-2.5 w-full overflow-hidden rounded-full">
        {/* Animated background */}
        <div className="animate-shimmer absolute h-full w-full bg-gradient-to-r from-stone-200 via-stone-100 to-stone-200 bg-[length:200%_100%]" />
        {/* Progress overlay */}
        <div
          className="relative h-full rounded-full bg-rf-medium-blue transition-all duration-500"
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
