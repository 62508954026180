import { useEffect, useRef } from "react";

/**
 * This hook manages focus trapping within a container, such as a modal or dialog.
 * Focus trapping ensures that keyboard navigation (e.g., Tab/Shift+Tab) remains
 * confined to the container while it is open. It also allows handling the Escape key
 * to close the container or perform a specific action.
 *
 * When the container opens:
 * - Focus is automatically set to the first focusable element.
 * - The user cannot navigate outside the container using the Tab key.
 * - Focus returns to the previously focused element when the container closes.
 */

interface UseFocusTrapOptions {
  isOpen: boolean;
  onEscape?: () => void;
}

export function useFocusTrap(options: UseFocusTrapOptions) {
  const { isOpen, onEscape } = options;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    const container = containerRef.current;
    if (!container) return;

    // Get all focusable elements
    const focusableElements = container.querySelectorAll<HTMLElement>(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    if (!focusableElements.length) return;

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    // Focus first element when modal opens
    firstElement.focus();

    // Save the element that had focus before modal opened
    const previousActiveElement = document.activeElement as HTMLElement;

    function handleKeyDown(e: KeyboardEvent) {
      switch (e.key) {
        case "Tab":
          if (e.shiftKey && document.activeElement === firstElement) {
            lastElement.focus();
            e.preventDefault();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            firstElement.focus();
            e.preventDefault();
          }
          break;
        case "Escape":
          if (onEscape) {
            onEscape();
          }
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      // Restore focus when modal closes
      if (previousActiveElement) {
        previousActiveElement.focus();
      }
    };
  }, [isOpen, onEscape]);

  return containerRef;
}
