// utility function to handle overflow and padding logic
import { isiPhone, isMobile } from "./deviceDetection";

let scrollPosition = 0;

export const toggleVerticalBodyScroll = (isOpen: boolean) => {
  const rightPadding = isMobile() ? "0px" : "10px";

  if (isiPhone()) {
    if (isOpen) {
      // Store the current scroll position
      scrollPosition = window.scrollY;

      // Lock body scroll by setting position to fixed
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = "100%";
      document.body.style.paddingRight = rightPadding;
    } else {
      // Restore scroll position
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      document.body.style.paddingRight = "0px";

      // Scroll back to the original position
      window.scrollTo(0, scrollPosition);
    }
  } else {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
      document.body.style.paddingRight = rightPadding;
    } else {
      // Restore scroll position
      document.body.style.overflowY = "scroll";
      document.body.style.paddingRight = "0px";

      // Scroll back to the original position
    }
  }
};
