import { SectionInfoType } from "../global/types";

export const COLORS = {
  // primary colors
  "rf-vibrant-red": "#F8481D",
  "rf-bright-red": "#FD602B",
  "rf-red": "#FD793E",
  "rf-light-red": "#FD9254",
  "rf-very-bright-red": "#F8481D",
  //secondary colors
  "rf-deep-blue": "#252E9E",
  "rf-medium-blue": "#4A52C3",
  "rf-cool-blue": "#575FD0",
  "rf-light-blue": "#7078E9",
  // light neutral colors
  "rf-very-dark-gray": "#171717",
  "rf-light-gray": "#D9D9D9",
  "rf-very-light-gray": "#EFEBEB",
  // neutral colors
  "rf-dark-brown": "#352E2C",
  "rf-warm-gray": "#7B6B66",
  "rf-light-beige": "#CCC8C7",
};

export const AddressSearchInfo: SectionInfoType = {
  id: "address-search",
  title: "Address Search",
};

export const AnswerToUserQuestionInfo: SectionInfoType = {
  id: "answer-to-user-question",
  title: "Answer to User Question",
};

export const PlanningAndZoningInfo: SectionInfoType = {
  id: "planning-and-zoning",
  title: "Planning & Zoning",
};

export const JurisdictionInfo: SectionInfoType = {
  id: "jurisdiction",
  title: "Jurisdiction",
};

export const PermittingTimelineInfo: SectionInfoType = {
  id: "permitting-timeline",
  title: "Permitting Timeline",
};

export const RelevantMotionsInfo: SectionInfoType = {
  id: "relevant-motions",
  title: "Relevant Motions",
};

export const AllSearchAddressInfos: SectionInfoType[] = [
  AddressSearchInfo,
  AnswerToUserQuestionInfo,
  PlanningAndZoningInfo,
  JurisdictionInfo,
  PermittingTimelineInfo,
  RelevantMotionsInfo,
];

export const ROUTES = {
  home: "/",
  demo: "/demo",
  account: "/account",
};

export const COORDS = {
  california: { lat: 33.7783232, lng: -119.4179321 },
};
