import React, { useRef, useState } from "react";
import { CopySelectRegular } from "@fluentui/react-icons";
import { twMerge } from "tailwind-merge";
import { useAppSelector } from "../../app/hooks";
import { useIsBelow } from "../../app/globals/screenSlice";
import Button from "../ui/Buttons/Button";

type FileType =
  | "pdf"
  | "doc"
  | "docx"
  | "image/png"
  | "image/jpeg"
  | "image/jpg"
  | "image/*";

interface FileDropzoneProps {
  onFileSelect: (files: FileList) => void;
  accept: FileType | FileType[];
  multiple?: boolean;
  capture?: "user" | "environment";
  skipDuplicates?: boolean;
  isDisabled?: boolean;
}

const FileDropzone = ({
  onFileSelect,
  accept = "image/*",
  multiple = false,
  // which phone camera to turn on for image type. user front. env back.
  capture = "environment",
  skipDuplicates = true,
  isDisabled,
}: FileDropzoneProps) => {
  const isBelowSm = useIsBelow("sm");

  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const [previousFiles, setPreviousFiles] = useState<Set<string>>(new Set());

  // Helper function to check if file is duplicate
  const isDuplicateFile = (file: File): boolean => {
    const fileKey = `${file.name}-${file.size}-${file.lastModified}`;
    return previousFiles.has(fileKey);
  };

  // Helper function to filter out duplicate files
  const filterDuplicates = (fileList: FileList): FileList => {
    if (!skipDuplicates) return fileList;

    const uniqueFiles = Array.from(fileList).filter((file) => {
      const isDuplicate = isDuplicateFile(file);
      if (!isDuplicate) {
        const fileKey = `${file.name}-${file.size}-${file.lastModified}`;
        setPreviousFiles((prev) => new Set([...prev, fileKey]));
      }
      return !isDuplicate;
    });

    const dataTransfer = new DataTransfer();
    uniqueFiles.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const acceptString = Array.isArray(accept)
    ? accept
        .map((type) => (type.startsWith("image/") ? type : `.${type}`))
        .join(",")
    : accept.startsWith("image/")
      ? accept
      : `.${accept}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files?.length) {
      const uniqueFiles = filterDuplicates(files);
      if (uniqueFiles.length > 0) {
        onFileSelect(uniqueFiles);
      }
    }
    event.target.value = "";
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;

    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let filesToProcess = e.dataTransfer.files;

      if (!multiple && e.dataTransfer.files.length > 1) {
        const singleFileList = new DataTransfer();
        singleFileList.items.add(e.dataTransfer.files[0]);
        filesToProcess = singleFileList.files;
      }

      const uniqueFiles = filterDuplicates(filesToProcess);
      if (uniqueFiles.length > 0) {
        onFileSelect(uniqueFiles);
      }
      e.dataTransfer.clearData();
    }
  };

  // Common input element used by both mobile and desktop views
  const fileInput = (
    <input
      ref={inputRef}
      type="file"
      className="hidden"
      onChange={handleChange}
      disabled={isDisabled}
      accept={acceptString}
      multiple={multiple}
      capture={capture}
    />
  );

  // Mobile view
  if (isBelowSm) {
    return (
      <div className="w-full shrink-0">
        {fileInput}
        <Button
          maxWidth={"full"}
          variant="secondary"
          onClick={handleClick}
          isDisabled={isDisabled}
          label={"Select"}
          icon={{ icon: <CopySelectRegular /> }}
        />
      </div>
    );
  }

  // Desktop view with drag and drop
  return (
    <div
      onClick={handleClick}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      className={twMerge(
        `flex h-full w-full flex-col justify-center gap-1.5 rounded-lg border border-dashed border-stone-300 bg-stone-50 py-6 transition-colors duration-300`,
        isDragging ? "bg-rf-light-blue" : "",
        isDisabled
          ? "cursor-not-allowed opacity-75"
          : "cursor-pointer hover:border-stone-400 hover:bg-stone-100 active:bg-stone-200",
      )}
    >
      {fileInput}

      <div className="text-center">
        <CopySelectRegular
          className={twMerge(
            "h-8 w-8 text-stone-700 duration-300",
            isDragging ? "text-stone-950" : "",
          )}
        />
      </div>

      <p className={twMerge("body-font text-center duration-300")}>
        {isDragging
          ? "Drop to upload"
          : `Drag and drop or click to ${
              multiple ? "select files" : "select a file"
            }`}
      </p>

      <p
        className={twMerge(
          "desc-font text-center text-stone-700 duration-300",
          isDragging ? "text-stone-950" : "",
        )}
      >
        {acceptString.split(",").join(" or ")}
      </p>
    </div>
  );
};

export default FileDropzone;
