import { TriangleDown16Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { UseFormSetValue, Control } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import ZoningFilters from "./ZoningFilters";
import PermitTypes from "./PermitTypes";
import SpecialProgram from "./SpecialProgram";
import SpecialityDataSources from "./SpecialityDataSources";
import SecuredData from "./SecuredData";

type Props = {
  setValue: UseFormSetValue<AddressSearchFormType>;
  zoningType: string | undefined;
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
  control: Control<AddressSearchFormType, any>;
};

export default function OptionalModifiers({
  setValue,
  address,
  coordinates,
  control,
  zoningType,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  return (
    <div>
      <button
        type="button"
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className="mx-auto flex w-full max-w-[462px] items-center gap-2 lg:mx-0"
      >
        <TriangleDown16Filled
          className={`${!isMenuOpen && "-rotate-90"} mt-[2px] text-rf-dark-brown duration-300`}
        />
        <p className="desc-font text-left">
          (Optional) Apply modifiers to your report
        </p>
      </button>
      {isMenuOpen && (
        <div className="mt-3 flex flex-col flex-wrap items-center justify-between gap-6 lg:mt-6 lg:flex-row lg:items-start xl:mt-8">
          {/* Zoning Filters */}
          <ZoningFilters
            key={address}
            zoningType={zoningType}
            setValue={setValue}
            address={address}
            coordinates={coordinates}
          />

          {/* Permit Type */}
          <PermitTypes control={control} />

          {/* Special Program */}
          <SpecialProgram control={control} />

          {/* Other Sources */}
          <SpecialityDataSources control={control} />

          {/* Secured Data */}
          <SecuredData control={control} />
        </div>
      )}
    </div>
  );
}
