import React from "react";
import { ListSectionType } from "../../../components/ui/ListDetails/ListDetails";
import { twMerge } from "tailwind-merge";
import { useAppDispatch } from "../../../app/hooks";
import { closeModal } from "../modalSlice";

export type ListDetailsCategorySelectorProps = {
  list: ListSectionType[];
  selectedIndex: string;
  handleSetIndex: (index: string) => void;
  isLoading?: boolean;
};

export default function ListDetailsCategorySelector({
  list,
  selectedIndex,
  handleSetIndex,
  isLoading,
}: ListDetailsCategorySelectorProps) {
  const dispatch = useAppDispatch();

  const indexArray = selectedIndex.split("-");
  const sectionIndex = parseInt(indexArray[0]) ?? 0;
  const itemsIndex = parseInt(indexArray[1]) ?? 0;

  const selectedItem = list.length > 0 && list[sectionIndex].items[itemsIndex];

  if (!list || !selectedItem)
    return <p className={"body-font text-center"}>No options found</p>;

  return (
    <div>
      {list.map((section, i) => (
        <div key={i}>
          {section.title && (
            <>
              <h3 className="body-font my-3 mt-3.5 line-clamp-1 overflow-hidden break-all px-3 font-semibold md:px-[18px] lg:px-6">
                {section.title}
              </h3>
              <hr className="line-break my-0" />
            </>
          )}
          {section.items.map((item, index) => {
            const isSelected = selectedIndex === `${i}-${index}`;

            return (
              <div key={`${i}-${index}`}>
                <button
                  disabled={isLoading}
                  onClick={() => {
                    handleSetIndex(`${i}-${index}`);
                    if (item.onClick) item.onClick();
                    dispatch(closeModal());
                  }}
                  className={`block w-full rounded-md px-2 py-4 text-start duration-300 hover:bg-stone-300`}
                >
                  <p className={"desc-font break-word line-clamp-2"}>
                    {item.desc}
                  </p>

                  {item.citation && (
                    <p
                      className={`citation-font line-clamp-1 break-all underline`}
                    >
                      {item.citation}
                    </p>
                  )}

                  {isSelected && item.counter && (
                    <p className={"citation-font line-clamp-1 break-all"}>
                      {item.counter}
                    </p>
                  )}
                </button>
                {index + 1 !== section.items.length && (
                  <div className="line-break mx-2 my-0"></div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
