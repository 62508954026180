import { useDeleteAddressMutation } from "../../../AddressSearchSection/AddressSearchSectionMutationsApiSlice";
import { Delete20Regular } from "@fluentui/react-icons";
import { StoredAddress } from "../../../../api/userConfig";
import { useAppSelector } from "../../../../app/hooks";
type Props = {
  address: StoredAddress;
};

export default function AddressListItem({ address }: Props) {
  const { session } = useAppSelector((state) => state.user);

  const userId = session ? session.user.id : "";

  const [deleteAddress, { isLoading: isDeleting }] = useDeleteAddressMutation();

  const handleDelete = async (id: number) => {
    try {
      await deleteAddress({ addressId: id, userId: userId }).unwrap();
    } catch (err) {
      console.error("Failed to delete address:", err);
    }
  };
  return (
    <div key={address.id} className="flex items-center justify-between gap-3">
      <p className="body-font line-clamp-1 grow">{address.address}</p>

      <button
        disabled={isDeleting}
        onClick={() => handleDelete(address.id)}
        className="shrink-0 rounded-md px-1 pb-0.5 text-rf-vibrant-red duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray"
      >
        <Delete20Regular />
      </button>
    </div>
  );
}
