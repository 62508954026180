import React from "react";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import { PlanningAndZoningInfo } from "../../helpers/demoSectionInfo";
import { useAppSelector } from "../../../../app/hooks";
import ReportSection from "../../../../components/ui/ReportSection/ReportSection";
import KeyValueSection from "../../../../components/ui/KeyValueSection/KeyValueSection";
import {
  zoningUseClassificationData,
  zoningInformationData,
  specificPlanAreaData,
  generalPlanLandUseData,
  overlaysData,
  easementsOrRightOfWays,
} from "./data/data";
import { renderParagraphs, renderSections } from "../../helpers/renderers";
import AccordionSectionSpacer from "../../../../components/ui/Accordion/AccordionSectionSpacer";
import PlanningAndZoningSkeleton from "./PlanningAndZoningSkeleton";
import ZoningSection from "../../../PlanningAndZoning/components/ZoningUseClassification/ZoningSection";

export default function PlanningAndZoning() {
  const { isLoading } = useAppSelector((state) => state.demo);

  if (isLoading) return <PlanningAndZoningSkeleton />;

  return (
    <Accordion
      title={PlanningAndZoningInfo.title}
      id={PlanningAndZoningInfo.id}
    >
      <AccordionSectionSpacer>
        {/* Zoning Use Classification */}
        <ReportSection
          secondaryTitle="RD1.5-1-O"
          title="Zoning Use Classification"
          collapsible
          limitMaxHeight={false}
          childPadding={false}
        >
          <div className="ml-1.5 mt-1.5 sm:ml-5 sm:mt-2.5">
            <AccordionSectionSpacer>
              {zoningUseClassificationData.map((item, index) => (
                <ZoningSection key={index} response={item} />
              ))}
            </AccordionSectionSpacer>
          </div>
        </ReportSection>

        {/* Zoning Information */}
        <ReportSection
          secondaryTitle="-"
          title="Zoning Information"
          collapsible
          isInitiallyOpen={false}
        >
          {renderParagraphs(zoningInformationData)}
        </ReportSection>

        {/* Specific Plan Area */}
        <ReportSection
          secondaryTitle="-"
          title="Specific Plan Area"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(specificPlanAreaData)}
        </ReportSection>

        {/* General Plan Land Use */}
        <ReportSection
          secondaryTitle="Low Medium II Residential"
          title="General Plan Land Use "
          collapsible
          isInitiallyOpen={false}
        >
          {renderParagraphs(generalPlanLandUseData)}
        </ReportSection>

        {/* Overlays */}
        <ReportSection secondaryTitle="-" title="Overlays" collapsible>
          {overlaysData.map((block, i) => (
            <div key={i}>
              {block.link.url ? (
                <a href={block.link.url} className="link mb-1.5 block w-fit">
                  {block.link.title}
                </a>
              ) : (
                <p className="body-font mb-1.5 block w-fit">
                  {block.link.title}
                </p>
              )}

              <p className="desc-font">{block.body ?? ""}</p>
              {overlaysData.length !== i + 1 && <hr className="line-break" />}
            </div>
          ))}
        </ReportSection>

        {/* Easements or Right-of-Ways*/}
        <ReportSection
          secondaryTitle="-"
          title="Easements or Right-of-Ways"
          collapsible
          isInitiallyOpen={false}
        >
          {renderSections(easementsOrRightOfWays)}
        </ReportSection>
      </AccordionSectionSpacer>
    </Accordion>
  );
}
