import { ModalTypes } from "../modalSlice";
import { ModalSize } from "../modalSlice";

interface ModalPreset {
  title: string;
  size: {
    width: ModalSize;
    height: ModalSize;
    mobileSize: ModalSize;
  };
}

const MODAL_PRESETS: Record<ModalTypes, ModalPreset> = {
  MotionsViewer: {
    title: "Motion",
    size: {
      width: "lg",
      height: "auto",
      mobileSize: "auto",
    },
  },
  ListDetailsCategorySelector: {
    title: "List",
    size: {
      width: "md",
      height: "auto",
      mobileSize: "auto",
    },
  },
  SearchError: {
    title: "Couldn't generate a report",
    size: {
      width: "md",
      height: "sm",
      mobileSize: "sm",
    },
  },
  SavedAddresses: {
    title: "My Addresses",
    size: {
      width: "lg",
      height: "lg",
      mobileSize: "lg",
    },
  },
  InfoSection: {
    title: "Additional Info",
    size: {
      width: "lg",
      height: "auto",
      mobileSize: "auto",
    },
  },
  BYODManager: {
    title: "File Manager",
    size: {
      width: "lg",
      height: "xl",
      mobileSize: "full",
    },
  },
} as const;

export const getModalPreset = (modalType: ModalTypes): ModalPreset => {
  return (
    MODAL_PRESETS[modalType] ?? {
      title: "",
      size: {
        width: "md",
        height: "md",
      },
    }
  );
};
