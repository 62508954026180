import React from 'react';
import {Search24Filled} from "@fluentui/react-icons";

interface QuerySectionProps {
  userQuery: string;
  answerFormat: string;
}

export function QuerySection({ userQuery, answerFormat }: QuerySectionProps) {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex items-center mb-4">
        <Search24Filled className="mr-2 text-blue-600" />
        <h2 className="text-xl font-semibold">Query Information</h2>
      </div>

      <div className="space-y-4">
        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">User Query</h3>
          <p className="text-gray-800 whitespace-pre-wrap">{userQuery}</p>
        </div>

        <div>
          <h3 className="text-sm font-medium text-gray-500 mb-1">Answer Format</h3>
          <pre className="bg-gray-50 p-3 rounded-md text-sm overflow-x-auto">
            {answerFormat}
          </pre>
        </div>
      </div>
    </div>
  );
}
