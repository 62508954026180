import React from "react";
import Button from "../../../components/ui/Buttons/Button";
import { useAppDispatch } from "../../../app/hooks";
import { closeModal } from "../modalSlice";
import { twMerge } from "tailwind-merge";

export type FooterActionsType = {
  secondaryBtn?: {
    label?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    action?: () => void;
  };
  primaryBtn?: {
    label?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    action: () => void;
  };
};

export default function FooterBtns({
  secondaryBtn,
  primaryBtn,
}: FooterActionsType) {
  const dispatch = useAppDispatch();

  const handleSecondaryAction = () => {
    if (secondaryBtn?.action) secondaryBtn.action();

    dispatch(closeModal());
  };

  const handlePrimaryAction = () => {
    if (primaryBtn?.action) primaryBtn.action();
  };

  return (
    <div className="flex flex-col-reverse gap-2 border-t border-stone-300 px-4 py-4 pb-6 sm:flex-row sm:px-6 sm:py-6">
      <div
        className={twMerge(
          "w-full max-w-[608px]",
          primaryBtn && "sm:basis-1/2",
        )}
      >
        <Button
          onClick={handleSecondaryAction}
          variant={`secondary`}
          maxWidth={"full"}
          label={secondaryBtn?.label || "Close"}
          isLoading={secondaryBtn?.isLoading}
          isDisabled={secondaryBtn?.isDisabled}
        />
      </div>
      {primaryBtn && (
        <div className="max-w-[608px] sm:basis-1/2">
          <Button
            onClick={handlePrimaryAction}
            variant="primary"
            maxWidth={"full"}
            label={primaryBtn.label ?? ""}
            isLoading={primaryBtn.isLoading}
            isDisabled={primaryBtn.isDisabled}
          />
        </div>
      )}
    </div>
  );
}
