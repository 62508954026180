import React, {useEffect, useState} from 'react';
import {OverlayList} from './OverlayList';
import {QuerySection} from './QuerySection';
import {CompletionSection} from './CompletionSection';
import {TimingInfo} from './TimingInfo';
import {supabase} from '../../api/db';
import {useParams} from "react-router-dom";
import {Spinner} from 'flowbite-react';
import {TelemetryData} from "./types/approach_log";

function ApproachView() {
  const {id} = useParams<{ id: string }>();
  const [approachData, setApproachData] = useState<any | TelemetryData>(null);
  const [expandedOverlay, setExpandedOverlay] = useState<number | null>(null);

  const handleToggleOverlay = (index: number) => {
    setExpandedOverlay(expandedOverlay === index ? null : index);
  };
  useEffect(() => {
    const fetchApproachData = async () => {
      console.log(id)
      try {
        const {data, error} = await supabase
          .from('rag_approach_log')
          .select('*')
          .eq('id', id)
          .single();

        if (error) {
          throw error;
        }

        if (data) {
          setApproachData(data.data);
        }
      } catch (error) {
        console.error('Error fetching approach data:', error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    };

    fetchApproachData();
  }, [id]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Approach Explorer</h1>
        {!!approachData ? (
          <>
            <h2 className="text-xl font-bold text-gray-900 mb-2">{approachData?.address ?? ''}</h2>
            <TimingInfo
              duration={approachData.duration}
              timestamp={approachData.timestamp}
            />

            <QuerySection
              userQuery={approachData.userQuery}
              answerFormat={approachData.answerFormat}
            />

            <div className="grid grid-cols-1 lg:grid-cols-1 gap-8 mb-8">

              <OverlayList
                overlays={approachData.overlays}
                expandedOverlay={expandedOverlay}
                onToggleOverlay={handleToggleOverlay}
              />
            </div>

            <CompletionSection
              interpretation={approachData.interpretation_completion}
              verification={approachData.verification_completion}
            />
          </>) : <Spinner/>}
      </div>
    </div>
  );
}

export default ApproachView;
