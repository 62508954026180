import React from 'react';
import { OverlayCard } from './OverlayCard';
import { Overlay } from './types/approach_log';

interface OverlayListProps {
  overlays: Overlay[];
  expandedOverlay: number | null;
  onToggleOverlay: (index: number) => void;
}

export function OverlayList({ overlays, expandedOverlay, onToggleOverlay }: OverlayListProps) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Overlay Search Results</h2>
      <div className="space-y-4">
        {overlays.map((overlay, index) => (
          <OverlayCard
            key={index}
            overlay={overlay}
            isExpanded={expandedOverlay === index}
            onToggle={() => onToggleOverlay(index)}
          />
        ))}
      </div>
    </div>
  );
}
