import React from 'react';
import {ChevronDown20Filled, ChevronUp20Filled} from "@fluentui/react-icons";

interface OverlayCardProps {
  overlay: {
    overlayName: string;
    generation?: string;
    numAnswers: number;
    searchQueries?: string[];
    semanticQuery?: string;
    searchCategories: string[];
  };
  isExpanded: boolean;
  onToggle: () => void;
}

export function OverlayCard({ overlay, isExpanded, onToggle }: OverlayCardProps) {
  const generation = overlay.generation ? JSON.parse(overlay.generation) : null;

  return (
    <div className="bg-white rounded-lg shadow-md p-4 mb-4">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={onToggle}
      >
        <h3 className="text-lg font-semibold">{overlay.overlayName}</h3>
        {isExpanded ? <ChevronUp20Filled /> : <ChevronDown20Filled />}
      </div>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          {generation && (
            <div>
              <h4 className="font-medium mb-2">Answer:</h4>
              <p className="text-gray-700">{generation.answer}</p>
            </div>
          )}

          {overlay.searchQueries && (
            <div>
              <h4 className="font-medium mb-2">Search Queries:</h4>
              <ul className="list-disc list-inside text-gray-700">
                {overlay.searchQueries.map((query, index) => (
                  <li key={index}>{query}</li>
                ))}
              </ul>
            </div>
          )}

          {overlay.semanticQuery && (
            <div>
              <h4 className="font-medium mb-2">Semantic Query:</h4>
              <p className="text-gray-700">{overlay.semanticQuery}</p>
            </div>
          )}

          <div>
            <h4 className="font-medium mb-2">Categories:</h4>
            <div className="flex flex-wrap gap-2">
              {overlay.searchCategories.map((category, index) => (
                <span
                  key={index}
                  className="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-sm"
                >
                  {category}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
