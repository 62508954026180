import { useController, Control } from "react-hook-form";
import Checkbox from "../Components/Checkbox";

type RHFCheckboxProps = {
  name: string;
  control: Control<any>;
  label: string;
  value: string;
  isDisabled?: boolean;
  className?: string;
  checkboxClass?: string;
  labelClass?: string;
};

export default function RHFCheckbox({
  name,
  control,
  label,
  value,
  isDisabled,
  className,
  checkboxClass,
  labelClass,
}: RHFCheckboxProps) {
  const {
    field: { onChange, value: fieldValue = [] },
  } = useController({
    name,
    control,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.checked
      ? [...fieldValue, value]
      : fieldValue.filter((v: string) => v !== value);
    onChange(updatedValue);
  };

  return (
    <Checkbox
      label={label}
      value={value}
      checked={fieldValue.includes(value)}
      onChange={handleChange}
      isDisabled={isDisabled}
      className={className}
      checkboxClass={checkboxClass}
      labelClass={labelClass}
    />
  );
}
