import React, { useState } from "react";
import ReportSection from "../ReportSection/ReportSection";
import ListButton from "./ListButton";
import Spinner from "../Loaders/Spinner";
import { twMerge } from "tailwind-merge";
import { Triangle16Filled } from "@fluentui/react-icons";
import { useAppDispatch } from "../../../app/hooks";
import { openModal } from "../../../features/modalManager/modalSlice";

export type ListItemType = {
  desc: string;
  citation?: string;
  counter?: string;
  onClick?: () => any;
};

export type ListSectionType = {
  title?: string;
  items: ListItemType[];
};

type Props = {
  title?: string;
  mobileDropdownTitle?: string;
  isInitiallyOpen?: boolean;
  children: React.ReactNode;
  collapsible?: boolean;
  className?: string;
  list: ListSectionType[];
  scrollableTarget?: string;
  loadingDetails?: boolean;
  errorDetails?: string | null;
  limitMaxHeight?: boolean;
  desc?: {
    citations: string[];
    answerElements: JSX.Element[];
  };
  headerClassName?: string;
  bodyClassName?: string;
  activeColor?: string;
};
export default function ListDetails({
  title,
  children,
  collapsible,
  className,
  list,
  scrollableTarget = "",
  loadingDetails,
  errorDetails,
  desc,
  isInitiallyOpen = true,
  limitMaxHeight,
  headerClassName,
  bodyClassName,
  activeColor,
  mobileDropdownTitle = "Select item",
}: Props) {
  const dispatch = useAppDispatch();
  const [selectedIndex, setSelectedIndex] = useState("0-0");

  const handleSetIndex = (index: string) => {
    setSelectedIndex(index);
  };

  const handleOpenListBottomSheet = () =>
    dispatch(
      openModal({
        type: "ListDetailsCategorySelector",
        title: "mobileDropdownTitle",
        props: {
          list: list,
          selectedIndex: selectedIndex,
          handleSetIndex: handleSetIndex,
          isLoading: loadingDetails,
        },
      }),
    );

  const selectedItem = (() => {
    const [sectionIndex, itemIndex] = selectedIndex.split("-").map(Number);
    return list[sectionIndex]?.items[itemIndex];
  })();

  return (
    <ReportSection
      title={title}
      collapsible={collapsible}
      className={className}
      childPadding={false}
      isInitiallyOpen={isInitiallyOpen}
      limitMaxHeight={limitMaxHeight}
      headerClassName={headerClassName}
    >
      {desc ? (
        <div className="bg-rf-very-light-gray px-2 py-2.5">
          <p className="body-font">Interpretation</p>
          {desc.answerElements.map((element, index) => (
            <span className="desc-font" key={index}>
              {element}
            </span>
          ))}
        </div>
      ) : (
        <></>
      )}
      <div
        className={twMerge(
          "flex flex-col gap-1.5 rounded-b-md bg-rf-very-light-gray md:flex-row",
          bodyClassName,
        )}
      >
        {/* desktop view list */}
        <div className="thin-scrollbar relative mb-3 hidden max-h-[80vh] min-h-[600px] w-full basis-[100%] overflow-y-scroll px-2.5 md:block md:max-w-[300px] lg:max-w-[340px]">
          <hr className="line-break absolute left-2.5 top-0 my-0 w-[calc(100%-20px)]" />
          {list.map((section, i) => (
            <div key={i}>
              {section.title && section.items.length > 0 && (
                <>
                  <h3 className="body-font my-3 mt-3.5 line-clamp-1 overflow-hidden break-all px-3 font-semibold md:px-[18px] lg:px-6">
                    {section.title}
                  </h3>
                  <hr className="line-break my-0" />
                </>
              )}
              {section.items.map((item, index) => (
                <ListButton
                  isLoading={loadingDetails}
                  handleSetIndex={handleSetIndex}
                  index={`${i}-${index}`}
                  selectedIndex={selectedIndex}
                  {...item}
                  key={`${i}-${index}`}
                  activeColor={activeColor}
                />
              ))}
            </div>
          ))}
        </div>

        {/* mobile view list */}
        {selectedItem && (
          <div className="block px-[10px] pb-1 md:hidden">
            <p className="body-font mb-1 font-medium">{mobileDropdownTitle}</p>
            <button
              disabled={loadingDetails}
              onClick={handleOpenListBottomSheet}
              className={`flex w-full items-center justify-between gap-2 rounded-md border bg-white px-4 py-2 text-start duration-300 hover:border-stone-300`}
            >
              <div>
                <p className={"desc-font break-word line-clamp-2"}>
                  {selectedItem.desc}
                </p>
                {selectedItem.citation && (
                  <p
                    className={`citation-font line-clamp-1 break-all underline`}
                  >
                    {selectedItem.citation}
                  </p>
                )}
                <p className={"citation-font line-clamp-1 break-all"}>
                  {selectedItem.counter}
                </p>
              </div>

              <div className="rotate-180 leading-none">
                <Triangle16Filled />
              </div>
            </button>
          </div>
        )}

        {/* details */}
        <div className="relative h-[700px] md:h-auto md:flex-1">
          <div className="absolute inset-0 m-3 ml-0 mt-0 border-b-[1px] border-r-[1px] border-rf-light-gray pl-[18px] pr-0 shadow-[0px_0px_20px_5px_#00000026_inset]">
            <div
              id={scrollableTarget}
              className="thin-scrollbar mr-1.5 flex h-full max-h-full flex-col overflow-y-scroll pr-1.5"
            >
              {loadingDetails && (
                <div className="flex grow items-center justify-center">
                  <Spinner className="mx-auto block" />
                </div>
              )}

              {errorDetails && !loadingDetails && (
                <p className="body-font flex h-full grow items-center justify-center">
                  {errorDetails}
                </p>
              )}

              {!loadingDetails && !errorDetails && children}
            </div>
          </div>
        </div>
      </div>
    </ReportSection>
  );
}
