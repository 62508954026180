import { Link } from "react-router-dom";
import { Person24Filled } from "@fluentui/react-icons";
import ProfileDropdown from "./ProfileDropdown";

export default function Navbar() {
  return (
    <header
      className={
        "z-10 flex h-[80px] flex-row items-center justify-between bg-rf-dark-brown px-9 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]"
      }
      role={"banner"}
    >
      <div className={"block text-transparent sm:hidden"}>
        <Person24Filled />
      </div>
      <Link to="/" className={`header-font text-nowrap font-bold text-white`}>
        redflag
      </Link>

      <ProfileDropdown />
    </header>
  );
}
