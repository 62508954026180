import {supabase} from "./db";

const BACKEND_URI = "";

import {
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    Config,
    SimpleAPIResponse,
    PermitTimelineRequest, SiteInfoRequest, GetMotionsRequest, AddressOptions, LivenessData
} from "./models";

export async function getHeaders(): Promise<Record<string, string>> {

    const session = await supabase.auth.getSession();
    if (session) {
        return {
            Authorization: `Bearer ${session.data.session?.access_token}`,
        };
    }

    return {};
}

export async function configApi(): Promise<Config> {
    const response = await fetch(`${BACKEND_URI}/config`, {
        method: "GET"
    });

    return (await response.json()) as Config;
}

export async function askApi(request: ChatAppRequest): Promise<ChatAppResponse> {
    const response = await fetch(`${BACKEND_URI}/ask`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    const parsedResponse: ChatAppResponseOrError = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse as ChatAppResponse;
}

export async function chatApi(request: ChatAppRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function addressSearchApi(request: ChatAppRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/address`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function siteInfoApi(request: SiteInfoRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/site`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function getTimeline(request: PermitTimelineRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/permits/timeline`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });
}

export async function getMotions(request: GetMotionsRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/motions`, {
        method: "POST",
        headers: {...(await getHeaders()), "Content-Type": "application/json"},
        body: JSON.stringify(request)
    })
}

export async function getSpeechApi(text: string): Promise<string | null> {
    return await fetch("/speech", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            text: text
        })
    })
        .then(response => {
            if (response.status == 200) {
                return response.blob();
            } else if (response.status == 400) {
                console.log("Speech synthesis is not enabled.");
                return null;
            } else {
                console.error("Unable to get speech synthesis.");
                return null;
            }
        })
        .then(blob => (blob ? URL.createObjectURL(blob) : null));
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function uploadFileApi(request: FormData): Promise<SimpleAPIResponse> {
    const response = await fetch("/upload", {
        method: "POST",
        headers: (await getHeaders()),
        body: request
    });

    if (!response.ok) {
        throw new Error(`Uploading files failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function deleteUploadedFileApi(filename: string): Promise<SimpleAPIResponse> {
    const response = await fetch("/delete_uploaded", {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify({ filename })
    });

    if (!response.ok) {
        throw new Error(`Deleting file failed: ${response.statusText}`);
    }

    const dataResponse: SimpleAPIResponse = await response.json();
    return dataResponse;
}

export async function listUploadedFilesApi(): Promise<string[]> {
    const response = await fetch(`/list_uploaded`, {
        method: "GET",
        headers: (await getHeaders())
    });

    if (!response.ok) {
        throw new Error(`Listing files failed: ${response.statusText}`);
    }

    const dataResponse: string[] = await response.json();
    return dataResponse;
}

export async function get_address_options(request: SiteInfoRequest): Promise<AddressOptions> {
    const response = await fetch(`/address_options`, {
        method: "POST",
        headers: { ...(await getHeaders()), "Content-Type": "application/json" },
        body: JSON.stringify(request)
    });

    if (!response.ok) {
        throw new Error(`Cannot get address options: ${response.statusText}`);
    }

    const parsedResponse: AddressOptions = await response.json();
    return parsedResponse;
}

export async function get_services_up(): Promise<LivenessData> {
    const response = await fetch(`/liveness`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
}
