import React, { useState, useEffect, useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { twMerge } from "tailwind-merge";
import { PlacementType } from "../../../global/types";
import { BreakpointKey, useIsBelow } from "../../../app/globals/screenSlice";

type Props = {
  children: React.ReactNode;
  content: string | string[] | React.ReactNode;
  className?: string;
  placement?: PlacementType;
  trigger?: "hover" | "click";
  maxWidth?: number;
  hideBelow?: BreakpointKey;
};

const Tooltip = ({
  children,
  content,
  className = "",
  placement = "top-center",
  trigger = "hover",
  maxWidth = 360,
  hideBelow = "sm",
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [actualPlacement, setActualPlacement] = useState(placement);
  const timeoutRef = useRef<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const isBelowBreakpoint = useIsBelow(hideBelow);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  useClickOutside(containerRef, () => {
    if (trigger === "click") {
      hide();
    }
  });
  const calculateOptimalPlacement = () => {
    if (!tooltipRef.current || !containerRef.current) return placement;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    // If we're near the right edge of the screen
    const isNearRight = containerRect.right + tooltipRect.width > viewportWidth;
    const isNearLeft = containerRect.left - tooltipRect.width < 0;
    const isNearTop = containerRect.top - tooltipRect.height < 0;
    const isNearBottom =
      containerRect.bottom + tooltipRect.height > viewportHeight;

    // If we're near right edge, prefer left placements
    if (isNearRight) {
      if (!isNearTop) return "top-left";
      if (!isNearBottom) return "bottom-left";
      return "left-center";
    }

    // If we're near left edge, prefer right placements
    if (isNearLeft) {
      if (!isNearTop) return "top-right";
      if (!isNearBottom) return "bottom-right";
      return "right-center";
    }

    // If we're near top, prefer bottom placements
    if (isNearTop) {
      if (!isNearRight) return "bottom-right";
      if (!isNearLeft) return "bottom-left";
      return "bottom-center";
    }

    // If we're near bottom, prefer top placements
    if (isNearBottom) {
      if (!isNearRight) return "top-right";
      if (!isNearLeft) return "top-left";
      return "top-center";
    }

    // If no edge conflicts, keep original placement
    return placement;
  };

  useEffect(() => {
    if (isVisible) {
      const optimalPlacement = calculateOptimalPlacement();
      if (optimalPlacement !== actualPlacement) {
        setActualPlacement(optimalPlacement);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (trigger === "hover") {
      show();
    }
  };

  const handleMouseLeave = () => {
    if (trigger === "hover") {
      hide();
    }
  };

  const arrowClasses: Record<PlacementType, string> = {
    "top-center":
      "bottom-0 left-1/2 -translate-x-1/2 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
    "right-center":
      "left-0 top-1/2 -translate-x-full -translate-y-1/2 border-r-stone-900 border-y-transparent border-l-transparent",
    "bottom-center":
      "top-0 left-1/2 -translate-x-1/2 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
    "left-center":
      "right-0 top-1/2 translate-x-full -translate-y-1/2 border-l-stone-900 border-y-transparent border-r-transparent",
    "top-left":
      "bottom-0 right-4 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
    "top-right":
      "bottom-0 left-4 translate-y-full border-t-stone-900 border-x-transparent border-b-transparent",
    "bottom-left":
      "top-0 right-4 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
    "bottom-right":
      "top-0 left-4 -translate-y-full border-b-stone-900 border-x-transparent border-t-transparent",
  };

  const renderContent = () => {
    if (typeof content === "string") {
      return <p className="desc-font text-white">{content}</p>;
    }

    if (Array.isArray(content)) {
      return (
        <div className="space-y-1">
          {content.map((text, index) => (
            <p key={index} className="desc-font text-white">
              {text}
            </p>
          ))}
        </div>
      );
    }

    return content;
  };

  return (
    <div
      ref={containerRef}
      className="relative w-full"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={trigger === "click" ? (isVisible ? hide : show) : undefined}
    >
      {children}
      {isVisible && (
        <div
          ref={tooltipRef}
          style={{
            maxWidth,
            width: "max-content",
          }}
          className={twMerge(
            "absolute z-10 rounded-lg bg-stone-900 px-3 py-2",
            actualPlacement === "top-center" &&
              "bottom-full left-1/2 mb-2 -translate-x-1/2",
            actualPlacement === "right-center" &&
              "left-full top-1/2 ml-2 -translate-y-1/2",
            actualPlacement === "bottom-center" &&
              "left-1/2 top-full mt-2 -translate-x-1/2",
            actualPlacement === "left-center" &&
              "right-full top-1/2 mr-2 -translate-y-1/2",
            actualPlacement === "top-left" && "bottom-full right-0 mb-2",
            actualPlacement === "top-right" && "bottom-full left-0 mb-2",
            actualPlacement === "bottom-left" && "right-0 top-full mt-2",
            actualPlacement === "bottom-right" && "left-0 top-full mt-2",
            className,
            isBelowBreakpoint ? "hidden" : "block",
          )}
          onMouseEnter={trigger === "click" ? undefined : hide}
        >
          {renderContent()}
          <div
            className={`absolute h-0 w-0 border-[6px] ${arrowClasses[actualPlacement]}`}
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
