import React, { useEffect, useState } from "react";
import { supabase } from "../../api";

interface Report {
  id: string;
  created_at: string;
  data: Record<string, any>;
  public: boolean;
  address: string | null;
}

interface StoredAnswer {
  address: string | null;
  answer: Record<string, any>;
  report: string;
  title: string;
}

const UserReportsPage: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [answers, setAnswers] = useState<StoredAnswer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);

  const fetchReports = async () => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.rpc("get_user_reports_with_address");

      if (error) {
        throw error;
      }

      setReports(data || []);
    } catch (err: any) {
      setError(err.message || "An error occurred while fetching reports.");
    } finally {
      setLoading(false);
    }
  };

  const fetchAnswersForReport = async (reportId: string) => {
    setLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.rpc("get_answers_for_report", { report_id: reportId });

      if (error) {
        throw error;
      }

      setAnswers(data || []);
      setSelectedReportId(reportId);
    } catch (err: any) {
      setError(err.message || "An error occurred while fetching answers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">User Reports</h1>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <table className="table-auto w-full border-collapse border border-gray-300 mb-8">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">ID</th>
                <th className="border border-gray-300 px-4 py-2">Created At</th>
                <th className="border border-gray-300 px-4 py-2">Data</th>
                <th className="border border-gray-300 px-4 py-2">Public</th>
                <th className="border border-gray-300 px-4 py-2">Address</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report.id}>
                  <td className="border border-gray-300 px-4 py-2">{report.id}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {new Date(report.created_at).toLocaleString()}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {JSON.stringify(report.data)}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {report.public ? "Yes" : "No"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">{report.address || "N/A"}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    <button
                      onClick={() => fetchAnswersForReport(report.id)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      View Answers
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedReportId && (
            <>
              <h2 className="text-xl font-bold mb-4">Answers for Report {selectedReportId}</h2>
              <table className="table-auto w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-300 px-4 py-2">Title</th>
                    <th className="border border-gray-300 px-4 py-2">Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {answers.map((answer, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2">{answer.title}</td>
                      <td className="border border-gray-300 px-4 py-2">
                        {JSON.stringify(answer.answer)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UserReportsPage;
