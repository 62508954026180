export const sb9HoverText = "WARNING On April 24, 2024, an LA County Superior Court ruled that charter cities are not subject to SB 9\n" +
    "The Housing Opportunity and More Efficiency (HOME) Act, is a California law that aims to increase housing supply across the State through streamlined measures that expand infill development opportunities, including making it easier to apply for a lot split or two-unit development on a single-family zone."
export const sb35HoverText = "Provides a streamlined and ministerial review path for projects in jurisdictions that are not meeting State housing goals (Regional Housing Need Allocation, or RHNA) at both lower income levels and market-rate income levels. Ministerial projects do not require the approval through a public hearing, review under the California Environmental Quality Act (CEQA), or Neighborhood Notification."
export const sb684HoverText = "Land use: streamlined approval processes: development projects of 10 or fewer residential units on urban lots under 5 acres."
export const bonusDensityHoverText = `Assembly Bill (AB) 2334 amends the State Density Bonus Law to expand the location where development concessions are provided for 100 percent affordable housing developments to include locations defined as a very low vehicle travel area. Eligible housing development projects located in these areas are not required to adhere to zoning controls on allowable density and are granted an additional three stories, or 33 feet in height, as well as four incentives or concessions.  
A "very low vehicle travel area" is defined in 65915(o)(4) to mean an urbanized area, as designated by the United States Census Bureau, where the existing residential development generates vehicle miles traveled per capita that is below 85 percent of either regional vehicle miles traveled per capita or city vehicle miles traveled per capita.  `
export const ab2097HoverText = `Pursuant to Assembly Bill (AB) 2097, the City of Los Angeles is prohibited from imposing or enforcing minimum parking requirements on any residential, commercial or other development project (excluding event centers, hotels and similar transient lodging) that are within a one-half mile radius of a Major Transit Stop. The Department of City Planning issued a memorandum on December 31, 2022 which serves as guidance for project applicants and staff on the implementation of AB 2097.`
export const ab2011HoverText = `Effective July 1, 2023, Assembly Bill (AB) 2011 allows eligible residential development projects complying with certain housing affordability and labor standards to be subject to a streamlined, ministerial review process that is exempt from CEQA in certain commercial areas. The bill creates separate site eligibility requirements, affordability requirements and development standards for two types of housing developments — 100% Affordable Housing and Mixed-Income housing. 
Under AB 2011, to qualify as a 100% Affordable Housing project, all units (except managers' units) must be dedicated to lower income households at an affordable cost. For Mixed-Income rental projects using AB 2011, there are two options: either 8% Very Low Income and 5% Extremely Low Income units for 55 years, or 15% Lower Income units. Mixed-Income owner-occupied projects must include either a 45-year provision of 30% Moderate Income units or 15% Lower Income units. `
export const ab98HoverText = "Effective January 1, 2026, Assembly Bill (AB) 98 establishes statewide standards for new or expanded logistics use developments, including warehouses. These standards include building design, parking, truck loading bays, landscaping buffers, entry gates, and signage. Facility operators must submit and enforce approved truck routing plans to minimize impacts on residential areas. The bill also requires a 2-to-1 replacement of demolished housing units occupied within the last 10 years, unless previously declared substandard. Cities and counties must update their circulation elements by January 1, 2028, to designate specific truck routes avoiding residential zones and sensitive receptors."

const eldercareHoverText="Alzheimer’s/ Dementia Care Housing, Assisted Living Care Housing, Senior Independent Housing and Skilled Nursing Care Housing"

const publicUseHoverText = "Designated Public Benefit Projects in LAMC"

export const programTypeOptions: SelectableSource[] =
    [
        {id: "los_angeles_program_condo_apts",  name: "Condominiums, Community Apartments, And Stock Cooperatives", hoverText: "Condominiums, Community Apartments, And Stock Cooperatives"},
        {id: "los_angeles_program_elder_care", name: "Elder Care Facilities", hoverText: eldercareHoverText},
        {id: "los_angeles_program_public_benefit", name: "Public Benefit Projects", hoverText: publicUseHoverText}
    ]

export const miscTypeOptions: SelectableSource[] = [
    {id: "california_misc_sb9", name: "Senate Bill 9", hoverText: sb9HoverText},
    {id: "california_misc_sb35", name: "Senate Bill 423 (formerly SB35)", hoverText: sb35HoverText},
    {id: "california_misc_sb684", name: "Senate Bill 684", hoverText: sb684HoverText},
    {id: "california_misc_bonus_density", name: "Bonus Density", hoverText: bonusDensityHoverText},
    {id: "california_misc_ab2097", name: "Assembly Bill 2097", hoverText: ab2097HoverText},
    {id: "california_misc_ab2011", name: "Assembly Bill 684", hoverText: ab2011HoverText},
    {id: "california_misc_ab98", name: "Assembly Bill 98", hoverText: ab98HoverText},
]
