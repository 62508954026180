import React, { useState } from "react";
import SearchForm from "./components/SearchForm/SearchForm";
import OptionalModifiers from "./components/OptionalModifiers/OptionalModifiers";
import ReportFormDrawer from "./components/ReportFormDrawer";
import { useAppDispatch } from "../../app/hooks";
import { useForm, SubmitHandler } from "react-hook-form";
import StreetViewSearchMap from "./components/StreetViewSearchMap";
import useSubmitSearchQuery from "./hooks/useSubmitSearchQuery";
import SubmitButton from "./components/SubmitButton";
import { useValidateAddress } from "./hooks/useValidateAddress";
import { AddressSearchInfo } from "../../helpers/constants";
import { toast } from "react-toastify";
import { setFetched, setLoading as setDemoLoading } from "../_Demo/demoSlice";
import SearchMap from "./components/SearchMap";
import Tabs, { TabType } from "../../components/ui/Buttons/Tabs";
import DocumentUpload from "./components/DocumentUpload";
import useSearchMutationState from "./hooks/useSearchMutationState";
import { twMerge } from "tailwind-merge";
import useIsDemo from "../../hooks/useIsDemo";

export type AddressSearchFormType = {
  //SearchForm
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
  userPrompt: string | undefined;
  //OptionalModifiers
  zoningType?: string;
  permitSubType?: string;
  programType?: string[];
  miscTypeSelected?: string[];
  userFilenames?: string[];
};

export type TabKey = "map" | "street";

//  todo : add initialize state, add error handling,

export default function AddressSearchSection() {
  const [tab, setTab] = useState<TabKey>("map");

  const { isUninitialized } = useSearchMutationState();

  const isDemo = useIsDemo();

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<AddressSearchFormType>({
    defaultValues: {
      permitSubType: "all",
    },
  });

  const { isLoading, validatedAddress, searchParams } = useValidateAddress({
    setValue,
  });

  const address = watch("address");
  const coordinates = watch("coordinates");
  const userPrompt = watch("userPrompt");
  const zoningType = watch("zoningType");
  const permitSubType = watch("permitSubType");
  const programType = watch("programType")?.[0];
  const miscTypeSelected = watch("miscTypeSelected") || [];
  const userFilenames = watch("userFilenames");

  const tabsData: TabType<TabKey>[] = [
    { id: "map", label: "Map " },
    {
      id: "street",
      label: "Street",
      isDisabled: !coordinates,
      disabledMessage: "Pick an address to access street view",
    },
  ];

  const [makeApiRequest] = useSubmitSearchQuery({
    userPrompt,
    permitSubType,
    zoningType,
    programType,
    miscTypeSelected,
    userFilenames,
  });

  const handleTabChange = (id: TabKey) => setTab(id);

  const onSubmit: SubmitHandler<AddressSearchFormType> = async (data) => {
    if (isDemo) {
      const demoDelay = 1.5 * 1000;

      dispatch(setDemoLoading(true));
      dispatch(setFetched(true));
      setTimeout(() => {
        dispatch(setDemoLoading(false));
      }, demoDelay);
      return;
    }

    const parkingQuestion =
      "How many parking spaces are required for this location?";
    const setbackQuestion =
      "What are the setback requirements for this location";
    const signageQuestion =
      "What are the signage requirements for this location";

    const questionArray = [parkingQuestion, signageQuestion, setbackQuestion];
    const coordsArray: [number, number] | undefined = coordinates
      ? [coordinates.lat, coordinates.lng]
      : undefined;

    if (!address)
      return toast.warning("Invalid address", { toastId: "invalidAddress" });

    if (!coordinates)
      return toast.warning("Invalid coordinates", { toastId: "invalidCoords" });

    try {
      await makeApiRequest(questionArray, address, coordsArray);
    } catch (error) {
      console.error("Report generation failed:", error);
    } finally {
    }
  };

  return (
    <div
      key={searchParams ? validatedAddress : undefined}
      id={AddressSearchInfo.id}
      className={twMerge(
        "flex w-full bg-white shadow-[0px_4px_14px_0px_rgba(0,0,0,0.25)]",
        isUninitialized
          ? "min-h-[calc(100vh-120px)]"
          : "max-h-[2160px] min-h-fit",
      )}
    >
      <div className="flex-shrink-0">
        <ReportFormDrawer />
      </div>
      <div className="defaultPageStyle flex grow flex-col justify-start pb-12 pt-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto flex w-full max-w-[1200px] flex-col items-center justify-between gap-6 lg:flex-row lg:items-start">
            <div className="flex w-full max-w-[462px] flex-col gap-3">
              <p className="header-font pb-4 text-center tracking-[-0.3px] lg:pb-8 lg:text-left">
                redflag report generator
              </p>
              <SearchForm
                setValue={setValue}
                address={address}
                userPrompt={userPrompt}
              />

              <div />
              <DocumentUpload />
              <div />

              <SubmitButton coordinates={coordinates} address={address} />
            </div>

            <div className="w-full max-w-[462px] lg:max-w-[556px]">
              <Tabs<TabKey>
                tabs={tabsData}
                activeTab={tab}
                onTabChange={handleTabChange}
                className="mb-2 rounded-[10px]"
              />

              <div className="h-[360px] w-full">
                {tab === "map" && (
                  <SearchMap isLoading={isLoading} coordinates={coordinates} />
                )}
                {tab === "street" && (
                  <StreetViewSearchMap
                    isLoading={isLoading}
                    coordinates={coordinates}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mx-auto mt-6 max-w-[1200px] lg:mt-12 xl:mt-16">
            <OptionalModifiers
              zoningType={zoningType}
              coordinates={coordinates}
              address={address}
              setValue={setValue}
              control={control}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
