import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";
import { TextBulletList16Filled } from "@fluentui/react-icons";
import useClickOutside from "../../../hooks/useClickOutside";
import { PlacementType } from "../../../global/types";
import Button from "../Buttons/Button";

type PopoverProps = {
  children: React.ReactNode;
  width?: number;
  placement?: PlacementType;
  isOpen: boolean;
  togglePopover: () => void;
};

export default function Popover({
  children,
  width = 250,
  placement = "bottom-center",
  isOpen,
  togglePopover,
}: PopoverProps) {
  const popoverRef = useRef<HTMLDivElement>(null);

  useClickOutside(popoverRef, () => {
    if (isOpen) togglePopover();
  });

  return (
    <div ref={popoverRef} className="relative inline-block">
      {/* Trigger Button */}

      <Button
        ariaLabel="Open navigation menu"
        variant="secondary"
        onClick={togglePopover}
        icon={{ icon: <TextBulletList16Filled />, rounded: true }}
      />

      {/* Popover Content */}
      {isOpen && (
        <div
          className={twMerge(
            "absolute z-20 rounded-md border border-rf-light-gray bg-white p-4 shadow-lg",
            placement === "top-center" &&
              "bottom-full left-1/2 mb-2 -translate-x-1/2 transform",
            placement === "right-center" &&
              "left-full top-1/2 ml-2 -translate-y-1/2 transform",
            placement === "bottom-center" &&
              "left-1/2 top-full mt-2 -translate-x-1/2 transform",
            placement === "left-center" &&
              "right-full top-1/2 mr-2 -translate-y-1/2 transform",
            placement === "top-left" && "bottom-full right-0 mb-2 transform",
            placement === "top-right" && "bottom-full left-0 mb-2 transform",
            placement === "bottom-left" && "right-0 top-full mt-2 transform",
            placement === "bottom-right" && "left-0 top-full mt-2 transform",
          )}
        >
          <div style={{ width: width }}>{children}</div>
        </div>
      )}
    </div>
  );
}
