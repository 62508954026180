import React from "react";
import {
  CheckmarkCircle24Regular,
  ErrorCircle24Regular,
} from "@fluentui/react-icons";
import KeyValueSection from "../ui/KeyValueSection/KeyValueSection";

interface CompletionSectionProps {
  interpretation: string;
  verification: string;
}

export function CompletionSection({
  interpretation,
  verification,
}: CompletionSectionProps) {
  const interpretationData = JSON.parse(interpretation);
  const verificationData = JSON.parse(verification);

  return (
    <>
      <h2 className="mb-4 text-xl font-semibold">Answer To User Question</h2>
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="space-y-6">
          <div>
            <div className="mb-4 flex items-center">
              <h2 className="text-xl font-semibold">Interpretation</h2>
            </div>
            <KeyValueSection content={[interpretationData.answer]} />
          </div>
          <div>
            <div className="mb-4 flex items-center">
              <CheckmarkCircle24Regular className="mr-2 text-green-600" />
              <h2 className="text-xl font-semibold">Verification</h2>
            </div>
            {verificationData.issue && (
              <div className="mb-3 rounded-md border border-yellow-200 bg-yellow-50 p-3">
                <p className="text-sm text-yellow-800">
                  {verificationData.issue}
                </p>
              </div>
            )}
            {verificationData.answer && (
              <KeyValueSection content={[verificationData.answer]} />
            )}
            {verificationData.no_sources && (
              <span>No Sources</span>
              )}
            </div>
        </div>
      </div>
    </>
  );
}
