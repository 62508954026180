import React, { useId, useRef } from "react";
import { twMerge } from "tailwind-merge";

type CheckboxProps = {
  id?: string;
  label: string;
  value?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  className?: string;
  checkboxClass?: string;
  labelClass?: string;
};

export default function Checkbox({
  id,
  label,
  value,
  checked,
  onChange,
  isDisabled = false,

  className,
  checkboxClass,
  labelClass,
}: CheckboxProps) {
  const generatedId = useId();
  const inputId = id ?? generatedId;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isDisabled) return;
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      inputRef.current?.click();
    }
  };
  return (
    <div
      id={`${inputId}-wrapper`}
      role="checkbox"
      aria-checked={checked}
      aria-disabled={isDisabled}
      aria-labelledby={`${inputId}-label`}
      tabIndex={isDisabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      className={twMerge(
        "flex w-full items-start gap-2 outline-none",
        "rounded-lg focus-visible:ring-1 focus-visible:ring-rf-medium-blue",
        isDisabled ? "opacity-75" : "",
        className,
      )}
    >
      <input
        ref={inputRef}
        type="checkbox"
        id={inputId}
        tabIndex={-1}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
        className={twMerge(
          "border-1 block h-6 w-6 cursor-pointer select-none rounded-md border-stone-950 text-rf-vibrant-red outline-none duration-150 focus:ring-transparent enabled:hover:border-rf-vibrant-red enabled:focus:border-rf-vibrant-red disabled:cursor-not-allowed",
          checkboxClass,
        )}
      />
      <label
        htmlFor={inputId}
        className={twMerge(
          "body-font line-clamp-3 break-words leading-6",
          isDisabled ? "cursor-not-allowed" : "cursor-pointer",
          labelClass,
        )}
      >
        {label}
      </label>
    </div>
  );
}
