import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state for the slice
interface InitialState {}

const initialState: InitialState = {};

const addressSearchSlice = createSlice({
  name: "addressSearch",
  initialState,
  reducers: {
    resetAddressSearchState() {
      return initialState;
    },
  },
});

export const {} = addressSearchSlice.actions;

export default addressSearchSlice.reducer;
