import React from "react";
import { twMerge } from "tailwind-merge";


type Props = {
  ariaLabel: string;
  children: React.ReactNode;
  className?: string;
};

export default function RadioContainer({
  ariaLabel,
  children,
  className,
}: Props) {
  return (
    <div
      role="radiogroup"
      aria-label={ariaLabel}
      className={twMerge("flex flex-col gap-1", className)}
    >
      {children}
    </div>
  );
}
