import { twMerge } from "tailwind-merge";
import { toast } from "react-toastify";

export type TabType<T extends string = string> = {
  id: T;
  label: string;
  isDisabled?: boolean;
  disabledMessage?: string;
};

type Props<T extends string> = {
  tabs: TabType<T>[];
  activeTab: T;
  onTabChange: (id: T) => void;
  className?: string;
};
export default function Tabs<T extends string>({
  tabs = [],
  activeTab,
  onTabChange,
  className,
}: Props<T>) {
  if (!tabs.length) return null;

  return (
    <div
      className={twMerge(
        "flex w-full gap-1 rounded-lg bg-stone-200 p-1",
        className,
      )}
    >
      {tabs.map((tab) => {
        const handleOnClick = () => {
          if (tab.isDisabled) {
            if (tab.disabledMessage)
              return toast.info(tab.disabledMessage, {
                toastId: tab.disabledMessage,
              });

            return;
          }

          onTabChange(tab.id);
        };

        return (
          <button
            type="button"
            key={tab.id}
            onClick={handleOnClick}
            className={twMerge(
              `body-font block flex-1 rounded-lg px-4 py-2 font-medium text-slate-700 duration-300 enabled:hover:bg-stone-100 enabled:active:bg-white`,
              activeTab === tab.id
                ? "enabled:hover:bg-whitetext-slate-950 bg-white"
                : " ",
              tab.isDisabled &&
                "cursor-not-allowed bg-transparent text-stone-400",
            )}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
}
