import React, {useState, useEffect, useRef, FormEvent} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Dropdown, IconButton, Modal, PrimaryButton, TextField} from "@fluentui/react";
import {ParsedAnnotation} from "../../../components/Answer";
import {DocumentCRUD} from "./documentCrud"
import {Document, Overlay} from "../../../api";

import DocumentRenderer, {
  DocumentRendererHandle
} from "../../../features/AnswerToUserQuestion/components/DocumentRenderer/DocumentRenderer";
import {IDropdownOption} from "@fluentui/react/lib/Dropdown";
import {Button} from "flowbite-react";
import {OverlayCrud} from "../overlays/OverlayCrud";


const DocumentAdmin: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sources, setSources] = useState<ParsedAnnotation[]>([]);
  const [filteredSources, setFilteredSources] = useState<ParsedAnnotation[]>([]);
  const [selectedSection, setSelectedSection] = useState<string | undefined>(undefined);
  const [availableSections, setAvailableSections] = useState<string[]>([]);
  const [textContent, setTextContent] = useState<string[]>([]);
  const [interpretationNotes, setInterpretationNotes] = useState<string>('');

  const [sectionCategory, setSectionCategory] = useState('');
  const [sectionFineCategory, setSectionFineCategory] = useState<string[]>([]);

  let [documentName, setDocumentName] = useState('');
  let [documentId, setDocumentId] = useState('');

  const crud = new DocumentCRUD();
  const DocumentRendererRef = useRef<DocumentRendererHandle>(null);

  const overlayService = new OverlayCrud();
  const [overlays, setOverlays] = useState<Overlay[]>([]);

  useEffect(() => {
    loadOverlays()
  }, []);

  useEffect(() => {
    const sourcesFromParams = searchParams.get('sources');
    const searchDocument = searchParams.get('document');

    if (sourcesFromParams) {
      try {
        const parsedSources: ParsedAnnotation[] = JSON.parse(sourcesFromParams);
        setSources(parsedSources);
      } catch (error) {
        console.error('Error parsing annotation sources from URL:', error);
      }
    }

    if (searchDocument) {
      const fetchData = async () => {
        try {
          let annotations = await crud.getAnnotationsFromDocument(searchDocument);
          const document = await crud.getDocument(searchDocument);
          setDocumentName(document?.name ?? '');
          setDocumentId(document?.id);
          const fetchedSources: ParsedAnnotation[] = annotations.map(annotation => {
            return new ParsedAnnotation(annotation.id, annotation.section, document?.id)
          });
          fetchedSources.sort(ParsedAnnotation.compare)
          const uniqueSections = fetchedSources.filter(x => !!x).reduce((acc, current) => {
            if (!acc.includes(current.section!)) {
              acc.push(current.section!);
            }
            return acc;
          }, [] as string[]);
          return {fetchedSources, uniqueSections};
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      }
      fetchData().then((data: { fetchedSources: ParsedAnnotation[], uniqueSections: string[] }) => {
        data.fetchedSources.sort();
        setSources(data.fetchedSources);
        setAvailableSections(data?.uniqueSections ?? []);
        updateFilteredSources(data.fetchedSources, selectedSection);
      });
    }
  }, [searchParams, selectedSection]);


  const updateFilteredSources = (sourcesToFilter: ParsedAnnotation[], section: string | undefined) => {
    const filtered = section
      ? sourcesToFilter.filter(source => source.section === section)
      : sourcesToFilter;
    setFilteredSources(filtered);
  };

  useEffect(() => {
    updateFilteredSources(sources, selectedSection);
  }, [selectedSection, sources]);

  useEffect(() => {
    if (DocumentRendererRef.current && filteredSources.length > 0) {
      DocumentRendererRef.current.gotoAnnotation(filteredSources[0].getFirstAnnotation());
    }
  }, [filteredSources]);

  useEffect(() => {
    if (!!selectedSection && filteredSources.length > 0) {
      (crud.getAnnotationDetails(filteredSources.map(src => src.getAllAnnotations()).flat()).then(async function (details) {
        const annotationDetails = (await details.json());
        setTextContent(annotationDetails.map((x: any) => (x.content) ?? []));
        setInterpretationNotes(annotationDetails[0].interpretation_note ?? '');
        setSectionCategory(annotationDetails[0].category ?? '');
        setSectionFineCategory(annotationDetails[0].category_fine ?? []);
      }))
    }
  }, [filteredSources]);


  function saveInterpretationNotes() {
    crud.setInterpretationNote(filteredSources.map(src => src.getAllAnnotations()).flat(), interpretationNotes);
  }

  function saveFineCategories() {
    crud.setFineCategories(filteredSources.map(src => src.getAllAnnotations()).flat(), sectionFineCategory);
  }

  function handleSectionChange(event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined): void {
    setSelectedSection(option?.text);
  }

  function saveDocumentName(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!!documentId) {
      return crud.updateDocument(documentId, {name: documentName});
    }
  }

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingSectionName, setEditingSectionName] = useState('');

  function openEditModal() {
    setEditingSectionName(selectedSection || '');
    setIsEditModalOpen(true);
  }

  function closeEditModal() {
    setIsEditModalOpen(false);
  }

  async function saveEditedSectionName() {
    let ids = filteredSources.map(annotation => annotation.getAllAnnotations()).flat()
    let prevSectionName = filteredSources[0].section;
    let resp = await crud.setSectionName(ids, editingSectionName)
    if (resp.status === 200) {
      for (let i = 0; i < availableSections.length; ++i) {
        if (availableSections[i] == prevSectionName) {
          availableSections[i] = editingSectionName;
          setSelectedSection(editingSectionName);
        }
      }
    }
    closeEditModal();
  }

  // Duplicated with OverlayAdmin
  const loadOverlays = async () => {
    try {
      const data = await overlayService.getOverlays();

      setOverlays(data);
      return data;
    } catch (error) {
      console.error('Failed to fetch overlays:', error);
      return [];
    }
  };

  return (
    <div>
      <form onSubmit={saveDocumentName}>
        <div className="text-lg flex items-center gap-2 justify-center">
          <label htmlFor="documentName">Document Name</label>
          <input
            id="documentName"
            onChange={(e) => setDocumentName(e.target.value)}
            value={documentName}
            style={{width: '50ch'}}
          />
          <PrimaryButton type="submit">Save</PrimaryButton>
        </div>
        <div className="text-lg flex items-center gap-2 justify-center">
          <label htmlFor="documentName">Section</label>
          <Dropdown
            options={[
              {key: 'blank', text: ''},
              ...availableSections.map(section => ({key: section, text: section}))
            ]}
            selectedKey={selectedSection || 'blank'}
            onChange={handleSectionChange}
            placeholder="Select a section"
            styles={{dropdown: {width: 300}}}
          />
          <IconButton
            iconProps={{iconName: 'Edit'}}
            onClick={openEditModal}
            disabled={!selectedSection}
          />
        </div>
      </form>
      <hr/>
      {filteredSources?.length > 0 && (
        <DocumentRenderer
          key={documentName}
          ref={DocumentRendererRef}
          citedSources={filteredSources}
          additionalSources={[]}
          highlightActiveSectionMode={true}
        />
      )}
      <form className="flex flex-col items-center gap-4 mt-4 w-full m-2">
        <div className="text-lg w-full">
          <label htmlFor="sectionNotes" className="block mb-2 font-bold text-lg">Text Content</label>
          <div className="text-content">
            {textContent.map((paragraph, index) => (
              <div>
                <p className={"font-bold my-1"}>{filteredSources[index]?.getAllAnnotations() ?? ''}</p>
                <p key={index} dangerouslySetInnerHTML={{__html: paragraph}}/>
              </div>
            ))}
          </div>

          {/*Hack because admin page. */}
          <style>{`
                      .text-content table {
                        border-collapse: collapse;
                        border: 1px solid black;
                      }
                      .text-content td, .text-content th {
                        border: 1px solid black;
                        padding: 8px;
                      }
                    `}</style>
          <label htmlFor="sectionNotes" className="block mb-2 font-bold text-lg my-2">Raw Data</label>
          {textContent.map(content =>
            <textarea
              id="sectionNotes"
              value={content}
              className="w-full h-32 p-2 border rounded"
            />)}
          <label htmlFor="sectionNotes" className="block mb-2 font-bold my-2">Section Notes</label>
          <textarea
            id="sectionNotes"
            placeholder="Enter notes to help the AI interpret this section"
            value={interpretationNotes}
            onChange={(event) => setInterpretationNotes(event.target.value)}
            disabled={!selectedSection}
            className="w-full h-32 p-2 border rounded"
          />
        <Button
          onClick={saveInterpretationNotes}
          disabled={!selectedSection}
          className="mt-2"
        >
            Save Section Notes
        </Button>
          <label htmlFor="sectionNotes" className="block mb-2 font-bold my-2">Section: {sectionCategory}</label>
          <label htmlFor="sectionNotes" className="block mb-2 font-bold my-2">Fine Sections</label>

          <div className={"sectionSelector"}>
            <Dropdown
              placeholder="Select an overlay"
              options={overlays
                .map((overlay) => ({
                  key: overlay.id,
                  text: overlay.name?.length ? overlay.id + ' : ' + overlay.name : overlay.id,
                }))}
              onChange={(_, option) => {
                if (option && !sectionFineCategory.includes(option.text)) {
                  setSectionFineCategory([...sectionFineCategory, (option.key as string)]);
                }
              }}
              className="mb-4"
            />

            {sectionFineCategory.map((category, index) => (
              <div key={index}
                   className="inline-flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                <span>{category} Overlay</span>
                <button
                  onClick={() => {
                    setSectionFineCategory(sectionFineCategory.filter((_, i) => i !== index));
                  }}
                  className="ml-2 focus:outline-none"
                >
                  <svg className="h-4 w-4 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"/>
                  </svg>
                </button>
              </div>
            ))}
              <Button
                onClick={saveFineCategories}
                disabled={!selectedSection}
                className="mt-2"
              >
                  Save Fine Sections
              </Button>
          </div>
        </div>
      </form>

      <Modal
        isOpen={isEditModalOpen}
        onDismiss={closeEditModal}
        isBlocking={false}
        containerClassName="w-1/3 p-4"
      >
        <h2 className="text-xl mb-4">Edit Section Name</h2>
        <TextField
          label="Section Name"
          value={editingSectionName}
          onChange={(_, newValue) => setEditingSectionName(newValue || '')}
        />
        <div className="mt-4 flex justify-end gap-2">
          <PrimaryButton onClick={saveEditedSectionName}>Save</PrimaryButton>
          <Button onClick={closeEditModal}>Cancel</Button>
        </div>
      </Modal>

    </div>

  );
};
export default DocumentAdmin;
