import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Document } from "../../api";

type InitialStateType = {
  isLoading: boolean;
  isFetched: boolean;
  fakeDocuments: Document[];
};

const initialState: InitialStateType = {
  isLoading: false,
  isFetched: false,
  fakeDocuments: [
    {
      created_at: "2025-01-06T18:06:56.542565+00:00",
      id: "Expedited processing available for projects with 50% affordable units. Height restrictions are waived for qualifying mixed-use developments",
      name: "SB423_Mixed_Development_Framework.pdf",
    },
    {
      created_at: "2025-01-06T18:08:56.542565+00:00",
      id: "Minimum staffing requirements of 1 caregiver per 8 residents. Building must include emergency power for all medical equipment",
      name: "AB684_ElderCare_Density_Guidelines.pdf",
    },
    {
      created_at: "2025-01-06T18:07:56.542565+00:00",
      id: "All units must provide at least 1.5 parking spaces per bedroom. Development must be within 0.5 miles of major transit stop",
      name: "CONDO_PB2024-35_Residential_Standards.pdf",
    },
  ],
};

const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFetched(state, action: PayloadAction<boolean>) {
      state.isFetched = action.payload;
    },
    resetDemoState() {
      return initialState;
    },
    addFakeDocument(state, action: PayloadAction<Document>) {
      state.fakeDocuments.push(action.payload);
    },
    removeFakeDocument(state, action: PayloadAction<string>) {
      state.fakeDocuments = state.fakeDocuments.filter(
        (doc) => doc.name !== action.payload,
      );
    },
  },
});

export const { setLoading, setFetched, addFakeDocument, removeFakeDocument } =
  demoSlice.actions;

export default demoSlice.reducer;
