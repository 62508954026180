import React, { useState, useRef } from "react";
import { useGetZoningOptionsQuery } from "../../addressSearchSectionApiSlice";
import {
  TriangleDown16Filled,
  SpinnerIos20Regular,
} from "@fluentui/react-icons";
import { UseFormSetValue } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import { useAppSelector } from "../../../../app/hooks";
import useSearchMutationState from "../../hooks/useSearchMutationState";
import CollapsibleSection from "./CollapsibleSection";
import SearchableInput from "../../../../components/forms/Components/SearchableInput";
import { getDropdownAriaLabels } from "../../../../helpers/ariaLabels";
import useIsDemo from "../../../../hooks/useIsDemo";

type Props = {
  address: string | undefined;
  zoningType: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
  setValue: UseFormSetValue<AddressSearchFormType>;
};

export default function ZoningFilters({
  address,
  coordinates,
  zoningType,
  setValue,
}: Props) {
  const isDemo = useIsDemo();

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const { isLoading: isResponseLoading } = useSearchMutationState();

  const isLoading = isResponseLoading || (isDemo && isDemoLoading);

  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const listRef = useRef<HTMLDivElement>(null);

  const dropdownLabels = getDropdownAriaLabels({
    title: "permit type",
    isOpen: isOpen,
  });

  const { data, isSuccess, error, isFetching } = useGetZoningOptionsQuery(
    {
      coordinates: [coordinates?.lat ?? 0, coordinates?.lng ?? 0],
      address: [address ?? ""],
    },
    { skip: !address || !coordinates || !isOpen },
  );

  // Filter the dropdown options based on the search query
  const filteredZones =
    (data &&
      data?.all_zones?.filter((zone) =>
        zone.toLowerCase().includes(searchQuery.toLowerCase()),
      )) ??
    [];

  const disableInput =
    isFetching ||
    data?.all_zones?.length === 0 ||
    isLoading ||
    !!error ||
    !data;

  const onBlur = (event: React.FocusEvent) => {
    if (
      listRef.current &&
      listRef.current.contains(event.relatedTarget as Node)
    ) {
      return;
    }
    if (searchQuery !== zoningType && zoningType && zoningType?.length > 0)
      setSearchQuery(zoningType);
  };

  const title = "View results for different zoning uses";

  if (error)
    return (
      <CollapsibleSection title={title}>
        <div className="h-[140px]">
          <p className="desc-font">Error loading options.</p>
        </div>
      </CollapsibleSection>
    );

  if (isFetching)
    return (
      <CollapsibleSection title={title}>
        <div className="flex h-[140px] flex-col justify-center">
          <SpinnerIos20Regular className="mx-auto animate-spin" />
        </div>
      </CollapsibleSection>
    );

  if (!isFetching && isSuccess && data?.all_zones?.length === 0)
    return (
      <CollapsibleSection title={title}>
        <div className="h-[140px]">
          <p className="desc-font">No available zones for this region.</p>
        </div>
      </CollapsibleSection>
    );

  return (
    <CollapsibleSection title="View results for different zoning uses">
      <div
        ref={listRef}
        className="flex h-fit flex-col gap-1.5 disabled:text-rf-warm-gray"
      >
        {/* Search Input */}
        <div className="flex items-center gap-1.5">
          <SearchableInput
            label="Select Type (Optional)"
            labelPosition="inline"
            isDisabled={disableInput}
            value={searchQuery}
            onBlur={onBlur}
            inputClassName="sm:h-[36px]"
            onClear={() => {
              setSearchQuery("");
              setValue("zoningType", undefined);
            }}
            onChange={(newValue) => {
              setIsOpen(true);
              setSearchQuery(newValue);
            }}
          />

          <button
            type="button"
            className="shrink-0"
            onClick={() => setIsOpen((prev) => !prev)}
            aria-label={dropdownLabels.currentLabel}
            aria-expanded={isOpen}
          >
            <TriangleDown16Filled
              className={`${!isOpen && "rotate-90"} mb-[3px] text-rf-dark-brown duration-300`}
            />
          </button>
        </div>
        {/* Dropdown Options */}
        {isOpen && (
          <>
            {filteredZones?.length > 0 && (
              <ul className="thin-scrollbar h-[140px] overflow-y-auto">
                {filteredZones.map((zone) => (
                  <li key={zone}>
                    <button
                      type="button"
                      disabled={disableInput}
                      onClick={() => {
                        if (zoningType !== zone) {
                          setValue("zoningType", zone);
                          setSearchQuery(zone);
                          setIsOpen(false);
                        }
                      }}
                      className="body-font block w-full cursor-pointer rounded-md px-2 py-1 text-start duration-300 hover:bg-rf-very-light-gray active:bg-rf-light-gray disabled:cursor-not-allowed disabled:text-rf-warm-gray"
                    >
                      {zone}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </CollapsibleSection>
  );
}
