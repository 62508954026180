import { Map, Marker } from "@vis.gl/react-google-maps";
import { twMerge } from "tailwind-merge";
import { useEffect, useState } from "react";
import { MapCameraChangedEvent } from "@vis.gl/react-google-maps";
import MapSkeleton from "../../../components/ui/Loaders/MapSkeleton";
import { isValidCoordinates } from "../../../helpers/locations";
import { COORDS } from "../../../helpers/constants";

interface Props {
  className?: string;
  isLoading?: boolean;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const SearchMap = ({ className, coordinates, isLoading }: Props) => {
  const cordsExist = !!coordinates;
  const [zoom, setZoom] = useState(16);

  const coordsValue = cordsExist ? coordinates : COORDS.california;

  const handleZoomChanged = (map: MapCameraChangedEvent) => {
    setZoom(map.detail.zoom);
  };

  useEffect(() => {
    setZoom(coordinates ? 16 : 6);
  }, [coordinates]);

  const mapKey = `${coordsValue?.lat ?? 0}-${coordsValue?.lng ?? 0}`;

  if (isLoading)
    return (
      <MapSkeleton className="h-full shadow-[0px_0px_10px_0px_#00000040]" />
    );

  return (
    coordsValue &&
    isValidCoordinates(coordsValue) && (
      <Map
        className={twMerge(
          "h-full w-full overflow-clip rounded-[10px] bg-rf-light-gray shadow-[0px_0px_10px_0px_#00000040]",
          className,
        )}
        key={mapKey}
        defaultCenter={coordsValue}
        zoom={cordsExist ? zoom : 6}
        zoomControl={cordsExist}
        onZoomChanged={(map) => handleZoomChanged(map)}
        mapTypeControl={false} // Disable map type control (satellite view)
        streetViewControl={false} // Disable street view control
        fullscreenControl={cordsExist} // Disable fullscreen control
        // onTilesLoaded={handleTilesLoaded} // Trigger when tiles are loaded
        gestureHandling={cordsExist ? "" : "none"} // Disable panning
      >
        {cordsExist && <Marker position={coordsValue} />}
      </Map>
    )
  );
};

export default SearchMap;
