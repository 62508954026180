import React, { useState } from "react";
import Button from "../../../components/ui/Buttons/Button";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { Dismiss16Filled } from "@fluentui/react-icons";
import { closeModal } from "../modalSlice";
import { ModalSize } from "../modalSlice";
import { twMerge } from "tailwind-merge";
import { useFocusTrap } from "../../../hooks/useFocusTrap";
import FooterBtns from "./FooterBtns";
import { FooterActionsType } from "../components/FooterBtns";

type Props = {
  SpecificModal: React.ComponentType<{
    setFooterActions?: React.Dispatch<React.SetStateAction<FooterActionsType>>;
  }>;
};

const MODAL_WIDTHS: Record<ModalSize, string> = {
  auto: "w-full max-w-lg", // 512px
  xs: "w-full max-w-sm", // 384px
  sm: "w-full max-w-md", // 448px
  md: "w-full max-w-lg", // 512px
  lg: "w-full max-w-xl", // 576px
  xl: "w-full max-w-3xl", // 768px
  full: "w-[90vw]",
};

const MODAL_HEIGHTS: Record<ModalSize, string> = {
  auto: "max-h-[90vh]", // Adapts to content
  xs: "h-[280px]", // Alerts, quick actions
  sm: "h-[350px]", // Simple forms
  md: "h-[440px]", // Standard content
  lg: "h-[580px]", // Complex forms
  xl: "h-[720px]", // Large content
  full: "h-[90vh]", // Full screen
};

export default function DesktopModal({ SpecificModal }: Props) {
  const [footerActions, setFooterActions] = useState<FooterActionsType>({});

  const isDisabled =
    footerActions.primaryBtn?.isLoading ||
    footerActions.secondaryBtn?.isLoading;

  const dispatch = useAppDispatch();

  const { isOpen, currentModal, title, width, height } = useAppSelector(
    (state) => state.modal,
  );

  const modalRef = useFocusTrap({
    isOpen,
    onEscape: () => dispatch(closeModal()),
  });

  const handleCloseModal = () => {
    if (isDisabled) return;

    dispatch(closeModal());
  };

  const handleClickOutside = (
    evt: React.MouseEvent<HTMLDivElement> | MouseEvent,
  ) => {
    let event = evt as MouseEvent;
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as HTMLElement)
    ) {
      handleCloseModal();
    }
  };

  if (!isOpen || !currentModal) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <div
        className={twMerge(
          "relative mx-[5vw] my-[5vh] flex max-h-[90vh] min-h-[200px] min-w-[400px] flex-col overflow-clip rounded-lg rounded-t-lg bg-white shadow-md",
          MODAL_WIDTHS[width],
          MODAL_HEIGHTS[height],
        )}
        ref={modalRef}
      >
        <div className="flex h-[40px] w-full shrink-0 items-center justify-between gap-3 bg-stone-200 pl-3 pr-0 text-left">
          <p className="body-font line-clamp-1 grow select-none break-all font-bold">
            {title}
          </p>

          <Button
            ariaLabel="Close modal"
            onClick={handleCloseModal}
            isDisabled={isDisabled}
            variant="ghost"
            icon={{ icon: <Dismiss16Filled /> }}
            className="rounded-bl-none rounded-br-none rounded-tl-none"
          />
        </div>

        <div className="thin-scrollbar flex grow flex-col overflow-y-scroll p-6 pb-6 pl-6 pr-4">
          <SpecificModal
            setFooterActions={setFooterActions}
            {...currentModal.props}
          />
        </div>

        <FooterBtns
          secondaryBtn={footerActions.secondaryBtn}
          primaryBtn={footerActions.primaryBtn}
        />
      </div>
    </div>
  );
}
