import { useAppSelector } from "../../../../app/hooks";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import CollapsibleSection from "./CollapsibleSection";
import { Control } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import useSearchMutationState from "../../hooks/useSearchMutationState";
import RHFRadio from "../../../../components/forms/RHFWrappers/RHFRadio";
import RadioContainer from "../../../../components/forms/Components/RadioContainer";
import { getDropdownAriaLabels } from "../../../../helpers/ariaLabels";
import useIsDemo from "../../../../hooks/useIsDemo";

type Props = {
  control: Control<AddressSearchFormType, any>;
};

const permitTypeOptions = {
  all: "All",
  "1 or 2 Family Dwelling": "1 or 2 Family Dwelling",
  apartment: "Apartment",
  commercial: "Commercial",
};

export default function PermitTypes({ control }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  const isDemo = useIsDemo();

  const dropdownLabels = getDropdownAriaLabels({
    title: "permit type",
    isOpen: isOpen,
  });

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const { isLoading: isResponseLoading } = useSearchMutationState();

  const isLoading = isResponseLoading || (isDemo && isDemoLoading) || !isOpen;

  return (
    <CollapsibleSection title="Filter Permitting Timelines by permit type">
      <RadioContainer ariaLabel="">
        {Object.entries(permitTypeOptions).map(([key, name], i) => {
          if (i !== 0 && !isOpen) return null;
          return (
            <div
              key={key}
              className="flex w-full items-start"
              aria-label={dropdownLabels.groupingLabel}
            >
              <RHFRadio
                control={control}
                name="permitSubType"
                value={key}
                label={name}
                isDisabled={isLoading}
                className="w-full"
              />
              {i === 0 && (
                <button
                  type="button"
                  onClick={() => setIsOpen(!isOpen)}
                  className="h-6 w-6 flex-shrink-0 translate-x-2"
                  aria-label={dropdownLabels.currentLabel}
                  aria-expanded={isOpen}
                >
                  <TriangleDown16Filled
                    className={twMerge(
                      "mb-[3px] text-rf-dark-brown duration-300",
                      !isOpen && "rotate-90",
                    )}
                  />
                </button>
              )}
            </div>
          );
        })}
      </RadioContainer>
    </CollapsibleSection>
  );
}
