import React from "react";
import Button from "../../../../components/ui/Buttons/Button";
import { DeleteRegular } from "@fluentui/react-icons";
import { twMerge } from "tailwind-merge";

export type FilesDisplayItemType = {
  name: string;
  descs: string[];
  id?: string;
};

type Props = {
  items: FilesDisplayItemType[];
  onClick: (index: number) => void;
  isDisabled?: boolean;
  className?: string;
};

export default function FilesDisplay({
  items = [],
  isDisabled,
  onClick,
  className,
}: Props) {
  if (items.length === 0) return <></>;

  return (
    <div className={twMerge("rounded-lg bg-stone-200 p-1", className)}>
      <div className="thin-scrollbar h-full overflow-y-scroll p-1 pl-2">
        <div className="flex flex-col gap-1">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between rounded-lg border border-stone-300 bg-stone-50 p-2 pl-5 pr-1"
            >
              <div className="flex flex-col">
                <p className="body-font line-clamp-1 break-all">{item.name}</p>
                {item.descs.map((desc, i) => (
                  <p key={i} className="desc-font text-stone-500">
                    {desc}
                  </p>
                ))}
              </div>

              <Button
                ariaLabel="Delete file"
                variant="ghost"
                isDisabled={isDisabled}
                onClick={() => onClick(index)}
                icon={{ icon: <DeleteRegular /> }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
