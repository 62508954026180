import React, { useState, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { TriangleDown16Filled } from "@fluentui/react-icons";

type Props = {
  title?: string;
  secondaryTitle?: string;
  desc?: string | JSX.Element;
  children?: React.ReactNode;
  className?: string;
  collapsible?: boolean;
  isInitiallyOpen?: boolean;
  childPadding?: boolean;
  limitMaxHeight?: boolean;
  headerClassName?: string;
};

export default function ReportSection({
  title,
  children,
  secondaryTitle,
  desc,
  className,
  collapsible = false,
  isInitiallyOpen = true,
  childPadding = true,
  limitMaxHeight = true,
  headerClassName,
}: Props) {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [isRendered, setIsRendered] = useState(isInitiallyOpen);
  const timeoutRef = useRef<number | undefined>(undefined);

  // this is here for mobile container scrolls. so that it animates smoothly.
  const toggleAccordion = () => {
    if (collapsible) {
      // Clear any active timeout when toggling
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (isOpen) {
        // If closing, delay the removal from the DOM to allow animation
        timeoutRef.current = window.setTimeout(() => {
          setIsRendered(false);
          timeoutRef.current = undefined; // Reset after clearing
        }, 300);
      } else {
        // If opening, render immediately before animation
        setIsRendered(true);
      }

      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={twMerge(" ", className)}>
      <div
        className={twMerge(
          `min-h-[30px] rounded-[5px] bg-rf-very-light-gray`,
          !childPadding && isOpen && "rounded-b-none",
          headerClassName,
        )}
      >
        <button
          className={twMerge(
            "w-full px-[10px] py-[3px] text-left",
            collapsible && children ? "cursor-pointer" : "cursor-default",
          )}
          onClick={toggleAccordion}
          aria-expanded={isOpen}
        >
          <div className="flex items-center gap-3">
            <h3 className="body-font line-clamp-1 grow break-all font-bold">
              {title}
            </h3>

            <h3 className="body-font line-clamp-1 shrink-0 break-all font-bold">
              {secondaryTitle}
            </h3>

            {collapsible && children && (
              <TriangleDown16Filled
                className={twMerge(
                  "shrink-0 text-[20px] leading-none text-rf-dark-brown transition-transform duration-300",
                  isOpen ? "" : "rotate-90",
                )}
              />
            )}
          </div>

          {desc && (
            <div className="desc-font pr-10">
              {typeof desc === "string" ? <p>{desc}</p> : desc}
            </div>
          )}
        </button>

        {desc && (
          <div
            className={twMerge(
              "animated-element grid transition-all duration-300 ease-in-out",
              isOpen
                ? "grid-rows-[1fr] pb-2 pt-2 opacity-100 sm:pb-3 sm:pt-2"
                : "grid-rows-[0fr] opacity-0",
            )}
          >
            <div className="line-break my-0" />
          </div>
        )}
      </div>

      {children && (
        <div
          className={twMerge(
            "animated-element grid bg-white transition-all duration-300 ease-in-out",
            isOpen
              ? "grid-rows-[1fr] opacity-100"
              : "grid-rows-[0fr] opacity-0",
            childPadding && (isOpen ? "m-1.5 sm:m-5" : " "),
          )}
        >
          {isRendered && (
            <div
              className={twMerge(
                "thin-scrollbar overflow-y-auto sm:overflow-hidden",
                childPadding && "pr-1.5 sm:pr-0",
                limitMaxHeight && "max-h-[820px] sm:max-h-fit",
              )}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
