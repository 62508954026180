import React, { useEffect, useState } from 'react';
import {get_services_up} from "../../api";
import {Spinner} from "../../components/HighlightDocContent/Spinner";

interface ServicesLiveness {
  [city: string]: {
    [service: string]: boolean;
  };
}

const ServiceChecks: React.FC = () => {
  const [livenessData, setLivenessData] = useState<ServicesLiveness>({});
  const [expandedRegions, setExpandedRegions] = useState<{[key: string]: boolean}>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLivenessData = async () => {
      try {
        setLoading(true);
        const data = await get_services_up();
        setLivenessData(data);
        const initialExpandedState = Object.keys(data).reduce((acc, region) => ({...acc, [region]: true}), {});
        setExpandedRegions(initialExpandedState);
      } catch (error) {
        console.error('Error fetching liveness data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLivenessData();
  }, []);

  const toggleRegion = (region: string) => {
    setExpandedRegions(prev => ({...prev, [region]: !prev[region]}));
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  return (
    <div>
      <div className = "w-full flex flex-inline justify-center">
        <h1 className={"text-lg justify-center"}>Services Liveness</h1>
      </div>
      {Object.entries(livenessData).map(([region, services], index) => (
        <div key={region} style={{marginBottom: '10px'}}>
          <div
            onClick={() => toggleRegion(region)}
            style={{cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white', padding: '10px'}}
          >
            <span style={{marginRight: '10px'}}>{expandedRegions[region] ? '▼' : '▶'}</span>
            {region}
          </div>
          {expandedRegions[region] && (
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', textAlign: 'left', width: '50%' }}>Service</th>
                  <th style={{ padding: '8px', textAlign: 'left', width: '50%' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(services).map(([service, status], rowIndex) => (
                  <tr key={`${region}-${service}`} style={{backgroundColor: rowIndex % 2 === 0 ? '#f9f9f9' : 'white'}}>
                    <td style={{ padding: '8px', textAlign: 'left' }}>{service}</td>
                    <td style={{ padding: '8px', textAlign: 'left' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: status ? '#00FF00' : '#f32727',
                          marginRight: '5px'
                        }}
                      ></span>
                      {status ? 'Online' : 'Offline'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceChecks;
