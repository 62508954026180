import React from "react";
import Button from "./Button";
import { Info24Regular } from "@fluentui/react-icons";
import Tooltip from "../Tooltip/Tooltip";
import { openModal } from "../../../features/modalManager/modalSlice";
import { useAppDispatch } from "../../../app/hooks";
import { useIsBelow } from "../../../app/globals/screenSlice";
import { PlacementType } from "../../../global/types";

type Props = {
  content: React.ReactNode | string | string[];
  placement?: PlacementType;
  type?: "tooltip" | "modal";
};

export default function InfoButton({
  content,
  placement,
  type = "tooltip",
}: Props) {
  const dispatch = useAppDispatch();
  const isBelowSm = useIsBelow("sm");

  const openInfoModal = () =>
    dispatch(
      openModal({
        type: "InfoSection",
        props: { content: content },
      }),
    );

  //   mobile screens
  if (isBelowSm)
    return (
      <Button
        ariaLabel="Info Button"
        icon={{ icon: <Info24Regular /> }}
        variant="ghost"
        onClick={openInfoModal}
      />
    );

  // modal
  if (type === "modal")
    return (
      <Button
        ariaLabel="Info Button"
        icon={{ icon: <Info24Regular /> }}
        variant="ghost"
        onClick={openInfoModal}
      />
    );

  // tooltip
  return (
    <Tooltip placement={placement} content={content} trigger={"click"}>
      <Button
        ariaLabel="Info Button"
        icon={{ icon: <Info24Regular /> }}
        variant="ghost"
      />
    </Tooltip>
  );
}
