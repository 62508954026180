import { MotionInfo } from "../../../api";

export type MotionsViewerProps = {
  motion: MotionInfo;
};

const MotionsViewer = ({ motion }: MotionsViewerProps) => {
  return (
    <div>
      <a
        className="link flex w-fit gap-1.5"
        href={motion.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {motion.title_summary}
      </a>

      <p className="body-font my-1.5">{motion.description} </p>

      <p className="citation-font">{motion.acting_body}</p>
    </div>
  );
};

export default MotionsViewer;
