import {
  ChatAppRequest,
  GPT4VInput,
  ResponseMessage,
  RetrievalMode,
  VectorFieldOptions,
} from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { openModal } from "../../modalManager/modalSlice";
import { useGetSearchConfigQuery } from "../addressSearchSectionApiSlice";
import {
  useGetZoningUseClassificationMutation,
  useAddressSearchMutation,
  useSiteInfoMutation,
  usePermitTimelineMutation,
  ZONING_USE_CLASSIFICATION_CACHE_KEY,
  ADDRESS_SEARCH_CACHE_KEY,
  SITE_INFO_CACHE_KEY,
  PERMIT_TIMELINE_CACHE_KEY,
} from "../AddressSearchSectionMutationsApiSlice";

type SearchConfigType = {
  promptTemplate: string;
  temperature: number;
  minimumRerankerScore: number;
  minimumSearchScore: number;
  retrieveCount: number;
  shouldStream: boolean;
  useSemanticCaptions: boolean;
  excludeCategory: string;
  useSuggestFollowupQuestions: boolean;
  vectorFieldList: VectorFieldOptions[];
  useOidSecurityFilter: boolean;
  useGroupsSecurityFilter: boolean;
  gpt4vInput: GPT4VInput;
  useGPT4V: boolean;
};

const searchConfig: SearchConfigType = {
  promptTemplate: "",
  temperature: 0.3,
  minimumRerankerScore: 0,
  minimumSearchScore: 0,
  retrieveCount: 3,
  shouldStream: false,
  useSemanticCaptions: false,
  excludeCategory: "",
  useSuggestFollowupQuestions: false,
  vectorFieldList: [VectorFieldOptions.Embedding],
  useOidSecurityFilter: false,
  useGroupsSecurityFilter: false,
  gpt4vInput: GPT4VInput.TextAndImages,
  useGPT4V: false,
};

type Props = {
  userPrompt: string | undefined;
  permitSubType: string | undefined;
  programType: string | undefined;
  miscTypeSelected: string[];
  zoningType: string | undefined;
  userFilenames?: string[];
};

export default function useSubmitSearchQuery({
  userPrompt,
  permitSubType,
  programType,
  miscTypeSelected,
  zoningType,
  userFilenames,
}: Props) {
  const dispatch = useAppDispatch();

  const { data: searchData } = useGetSearchConfigQuery();

  const [getZoningUseClassification] = useGetZoningUseClassificationMutation({
    fixedCacheKey: ZONING_USE_CLASSIFICATION_CACHE_KEY,
  });

  const [addressSearch] = useAddressSearchMutation({
    fixedCacheKey: ADDRESS_SEARCH_CACHE_KEY,
  });
  const [getSiteInfo] = useSiteInfoMutation({
    fixedCacheKey: SITE_INFO_CACHE_KEY,
  });
  const [getTimeline] = usePermitTimelineMutation({
    fixedCacheKey: PERMIT_TIMELINE_CACHE_KEY,
  });

  const useSemanticRanker = searchData
    ? searchData.showSemanticRankerOption
    : true;
  const retrievalMode =
    searchData && searchData.showVectorOption
      ? RetrievalMode.Text
      : RetrievalMode.Hybrid;

  const openMotionModal = () => {
    dispatch(
      openModal({
        type: "SearchError",
        title: "Couldn't generate a report",
      }),
    );
  };

  // Site Info Request
  const fetchSiteInfo = async ({
    location,
    coords,
  }: {
    location: string;
    coords?: [number, number];
  }) => {
    try {
      await getSiteInfo({
        address: location,
        coordinates: coords,
      }).unwrap();
    } catch (error) {
      console.error("Site info request failed:", error);
    }
  };

  // Address Search Request
  const fetchAddressSearch = async ({
    request,
  }: {
    request: ChatAppRequest;
  }) => {
    try {
      if (!userPrompt) return;

      const openEndedRequest = { ...request, user_query: userPrompt };
      await addressSearch(openEndedRequest).unwrap();
    } catch (error) {
      console.error("Address search request failed:", error);
      openMotionModal();
    }
  };

  // Timeline Request
  const fetchTimeline = async ({
    location,
    coords,
  }: {
    location: string;
    coords?: [number, number];
  }) => {
    try {
      await getTimeline({
        address: location,
        coordinates: coords,
        permitSubType,
        change_zone: zoningType,
      }).unwrap();
    } catch (error) {
      console.error("Timeline request failed:", error);
    }
  };

  // Zoning Classification Request
  const fetchZoningClassification = async ({
    location,
    coords,
  }: {
    location: string;
    coords?: [number, number];
  }) => {
    try {
      if (!coords || !location) return;

      await getZoningUseClassification({
        coords: coords,
        location: location,
        checklistId: 25,
        userFilenames: userFilenames,
      }).unwrap();
    } catch (error) {
      console.error("Zoning classification request failed:", error);
    }
  };

  const makeApiRequest = async (
    [parkingQuery, signageQuery, setbackQuery]: string[],
    location: string,
    coords?: [number, number],
  ): Promise<void> => {
    const request: ChatAppRequest = {
      messages: [{ content: parkingQuery, role: "user" }],
      location: location,
      stream: searchConfig.shouldStream,
      user_query: parkingQuery,
      change_zone: zoningType,
      additional_categories: [
        ...miscTypeSelected,
        ...(programType ? [programType] : []),

      ],
      user_filenames: userFilenames,
      context: {
        overrides: {
          prompt_template:
            searchConfig.promptTemplate.length === 0
              ? undefined
              : searchConfig.promptTemplate,
          exclude_category:
            searchConfig.excludeCategory.length === 0
              ? undefined
              : searchConfig.excludeCategory,
          top: searchConfig.retrieveCount,
          temperature: searchConfig.temperature,
          minimum_reranker_score: searchConfig.minimumRerankerScore,
          minimum_search_score: searchConfig.minimumSearchScore,
          retrieval_mode: retrievalMode,
          semantic_ranker: useSemanticRanker,
          semantic_captions: searchConfig.useSemanticCaptions,
          suggest_followup_questions: searchConfig.useSuggestFollowupQuestions,
          use_oid_security_filter: searchConfig.useOidSecurityFilter,
          use_groups_security_filter: searchConfig.useGroupsSecurityFilter,
          vector_fields: searchConfig.vectorFieldList,
          use_gpt4v: searchConfig.useGPT4V,
          gpt4v_input: searchConfig.gpt4vInput,
          address_coordinates: coords,
        },
      },
      // ChatAppProtocol: Client must pass on any session state received from the server
      session_state: null,
    };

    await Promise.all([
      fetchAddressSearch({ request }),
      fetchSiteInfo({
        location,
        coords,
      }),
      fetchTimeline({
        location,
        coords,
      }),
      fetchZoningClassification({
        location,
        coords,
      }),
    ]);
  };

  return [makeApiRequest] as const;
}
