import { useController, Control } from "react-hook-form";
import Radio from "../Components/Radio";

type RHFRadioProps = {
  name: string;
  control: Control<any>;
  label: string;
  value: string;
  rules?: object;
  isDisabled?: boolean;
  className?: string;
  radioClass?: string;
  labelClass?: string;
};

export default function RHFRadio({
  name,
  control,
  label,
  value,
  rules,
  isDisabled,
  className,
  radioClass,
  labelClass,
}: RHFRadioProps) {
  const {
    field: { onChange, value: fieldValue },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <Radio
      name={name}
      label={label}
      value={value}
      checked={fieldValue === value}
      onChange={onChange}
      isDisabled={isDisabled}
      className={className}
      radioClass={radioClass}
      labelClass={labelClass}
    />
  );
}
