import {
  ChatAppRequestOverrides,
  ChatAppResponse,
  getHeaders,
} from "../../api";
import addressSearch from "../../pages/addressSearch/AddressSearch";

export interface ChecklistRequest {
  query: string;
  format: string;
  title: string;
}

export interface AdminChecklistRequest {
  queries: ChecklistRequest[];
  coords: number[];
  location: string;
  context: {
    overrides: ChatAppRequestOverrides;
  };
}

export interface ChecklistResponse {
  report_id: number;
  results: ChatAppResponse[];
}

export async function submitChecklist(
  queries: ChecklistRequest[],
  coordinates: number[],
  address = "",
  intermediatePrompt: string = "",
  finalizedPrompt: string = "",
): Promise<ChecklistResponse> {
  const request: AdminChecklistRequest = {
    queries: queries,
    coords: coordinates,
    location: address,
    context: {
      overrides: {
        vector_fields: [],
        intermediate_prompt: intermediatePrompt,
        finalized_prompt: finalizedPrompt,
      },
    },
  };

  try {
    const response = await fetch("/formatted_answer/checklist", {
      method: "POST",
      headers: { ...(await getHeaders()), "Content-Type": "application/json" },
      body: JSON.stringify(request),
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error(
      error instanceof Error ? error.message : "Failed to submit checklist",
    );
  }
}

export async function fetchIntermediatePrompt(): Promise<string> {
  const response = await fetch("/formatted_answer/intermediate_prompt", {
    headers: { ...(await getHeaders()), "Content-Type": "application/text" },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch intermediate prompt");
  }
  return await response.text();
}

export async function fetchFinalizedPrompt(): Promise<string> {
  const response = await fetch("/formatted_answer/finalized_prompt", {
    headers: { ...(await getHeaders()), "Content-Type": "application/text" },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch finalized prompt");
  }
  return await response.text();
}
