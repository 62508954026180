import { useAppSelector } from "../../../../app/hooks";
import * as LosAngeles from "../../../../regiondata/losangeles";
import { TriangleDown16Filled } from "@fluentui/react-icons";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import CollapsibleSection from "./CollapsibleSection";
import { Control } from "react-hook-form";
import { AddressSearchFormType } from "../../AddressSearchSection";
import useSearchMutationState from "../../hooks/useSearchMutationState";
import RHFCheckbox from "../../../../components/forms/RHFWrappers/RHFCheckbox";
import { SpinnerIos20Regular } from "@fluentui/react-icons";
import {
  useGetUserFilesQuery,
  GetUserFilesParamsType,
} from "../../addressSearchSectionApiSlice";
import { getDropdownAriaLabels } from "../../../../helpers/ariaLabels";
import useIsDemo from "../../../../hooks/useIsDemo";

type Props = { control: Control<AddressSearchFormType, any> };

const getUserFilesParams: GetUserFilesParamsType = {
  category: "get_user_documents",
};

export default function SecuredData({ control }: Props) {
  const [isOpen, setIsOpen] = useState(true);
  const isDemo = useIsDemo();
  const { fakeDocuments } = useAppSelector((state) => state.demo);

  const {
    data: documentsData,
    isFetching,
    isError,
    error,
  } = useGetUserFilesQuery(getUserFilesParams, { skip: isDemo });

  const data = isDemo ? fakeDocuments : documentsData;

  const dropdownLabels = getDropdownAriaLabels({
    title: "Secured data",
    isOpen: isOpen,
  });

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const { isLoading: isResponseLoading } = useSearchMutationState();

  const isLoading =
    isResponseLoading || (isDemo && isDemoLoading) || !isOpen || isFetching;

  const title = "My Secured Data";

  if (error)
    return (
      <CollapsibleSection title={title}>
        <div className="flex h-[140px] flex-col justify-center">
          <p className="desc-font text-center">{JSON.stringify(error)}</p>
        </div>
      </CollapsibleSection>
    );

  if (isFetching)
    return (
      <CollapsibleSection title={title}>
        <div className="flex h-[140px] flex-col justify-center">
          <SpinnerIos20Regular className="mx-auto animate-spin" />
        </div>
      </CollapsibleSection>
    );

  if (!data || data.length === 0) return <></>;

  return (
    <CollapsibleSection title={title}>
      {data.map((item, index) => {
        if (index !== 0 && !isOpen) return null;
        return (
          <div
            key={item.id + index}
            className="flex w-full items-start justify-between"
            aria-label={dropdownLabels.groupingLabel}
          >
            <RHFCheckbox
              control={control}
              name="userFilenames"
              value={item.id}
              label={item.name || item.id}
              isDisabled={isLoading}
              className={twMerge("w-full", index === 0 && "w-[88%]")}
            />
            {index === 0 && (
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="h-6 w-6 flex-shrink-0 translate-x-2"
                aria-label={dropdownLabels.currentLabel}
                aria-expanded={isOpen}
              >
                <TriangleDown16Filled
                  className={twMerge(
                    "mb-[3px] text-rf-dark-brown duration-300",
                    !isOpen && "rotate-90",
                  )}
                />
              </button>
            )}
          </div>
        );
      })}
    </CollapsibleSection>
  );
}
