import React from "react";

export type InfoSectionProps = {
  content: string | string[] | React.ReactNode;
};

export default function InfoSection({ content }: InfoSectionProps) {
  const renderContent = () => {
    if (typeof content === "string") {
      return <p className="body-font">{content}</p>;
    }

    if (Array.isArray(content)) {
      return (
        <div className="space-y-1">
          {content.map((text, index) => (
            <p key={index} className="body-font">
              {text}
            </p>
          ))}
        </div>
      );
    }

    return content;
  };

  return <div>{renderContent()}</div>;
}
