import Accordion from "../../components/ui/Accordion/Accordion";
import { MotionsContent } from "./components/MotionsContent";
import RelevantMotionsSkeleton from "./components/RelevantMotionsSkeleton";
import { useAppSelector } from "../../app/hooks";
import { RelevantMotionsInfo } from "../../helpers/constants";
import useSearchMutationState from "../AddressSearchSection/hooks/useSearchMutationState";

export default function RelevantMotions() {
  const {
    mutations: {
      siteInfo: { data, isLoading },
    },
  } = useSearchMutationState();

  if (isLoading) return <RelevantMotionsSkeleton />;

  const councilDistrict = Number(data?.council_district);

  if (!data?.council_district || isNaN(councilDistrict)) return <></>;

  return (
    <Accordion id={RelevantMotionsInfo.id} title={RelevantMotionsInfo.title}>
      <MotionsContent />
    </Accordion>
  );
}
