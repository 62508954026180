export const getDropdownAriaLabels = ({
  title,
  isOpen,
}: {
  title: string;
  isOpen: boolean;
}) => {
  const openLabel = `Open ${title} dropdown`;
  const closeLabel = `Close ${title} dropdown`;
  const groupingLabel = `${title} selection`;
  return {
    openLabel,
    closeLabel,
    groupingLabel,
    currentLabel: isOpen ? closeLabel : openLabel,
  };
};
