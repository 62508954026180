import {
  useGetZoningUseClassificationMutation,
  useAddressSearchMutation,
  useSiteInfoMutation,
  usePermitTimelineMutation,
  ZONING_USE_CLASSIFICATION_CACHE_KEY,
  ADDRESS_SEARCH_CACHE_KEY,
  SITE_INFO_CACHE_KEY,
  PERMIT_TIMELINE_CACHE_KEY,
} from "../AddressSearchSectionMutationsApiSlice";
import {
  SiteInfoResponse,
  ChatAppResponse,
  PermitTimelineResponse,
} from "../../../api";
import { ZoningUseClassificationTransformedPayloadType } from "../AddressSearchSectionMutationsApiSlice";
import mockData from "../mockData.json";

declare global {
  var USE_MOCK_DATA: boolean;
}

function useMockData(): boolean {
  return typeof process !== "undefined" && process.env?.USE_MOCK_DATA === "true";
}

type MutationType = "zoning" | "address" | "siteInfo" | "timeline";

type MutationDataMap = {
  zoning: ZoningUseClassificationTransformedPayloadType[];
  address: ChatAppResponse;
  siteInfo: SiteInfoResponse;
  timeline: PermitTimelineResponse;
};

interface SearchMutationState {
  isLoading: boolean;
  isUninitialized: boolean;
  isError: boolean;
  errors: Array<{ type: MutationType; error: any }>;

  mutations: {
    [K in MutationType]: {
      isLoading: boolean;
      isUninitialized: boolean;
      data?: MutationDataMap[K];
      error: any;
    };
  };
}

const mockMutationStates: SearchMutationState["mutations"] = {
  zoning: {
    isLoading: false,
    isUninitialized: false,
    data: mockData.zoningChecklistResponse as ZoningUseClassificationTransformedPayloadType[],
    error: null,
  },
  address: {
    isLoading: false,
    isUninitialized: false,
    //@ts-ignore
    data: mockData.address as ChatAppResponse,
    error: null,
  },
  siteInfo: {
    isLoading: false,
    isUninitialized: false,
    //@ts-ignore
    data: mockData.siteInfoResponse as SiteInfoResponse,
    error: null,
  },
  timeline: {
    isLoading: false,
    isUninitialized: false,
    //@ts-ignore
    data: mockData.timeline as PermitTimelineResponse,
    error: null,
  },
};

export default function useSearchMutationState(
  mutations: MutationType[] = ["zoning", "address", "siteInfo", "timeline"],
): SearchMutationState {
  const isDevelopment = useMockData();

  const [
    ,
    {
      isLoading: zoningLoading,
      isUninitialized: zoningUninitialized,
      data: zoningData,
      error: zoningError,
    },
  ] = useGetZoningUseClassificationMutation({
    fixedCacheKey: ZONING_USE_CLASSIFICATION_CACHE_KEY,
  });

  const [
    ,
    {
      isLoading: addressLoading,
      isUninitialized: addressUninitialized,
      data: addressData,
      error: addressError,
    },
  ] = useAddressSearchMutation({
    fixedCacheKey: ADDRESS_SEARCH_CACHE_KEY,
  });
  const [
    ,
    {
      isLoading: siteInfoLoading,
      isUninitialized: siteInfoUninitialized,
      data: siteInfoData,
      error: siteInfoError,
    },
  ] = useSiteInfoMutation({
    fixedCacheKey: SITE_INFO_CACHE_KEY,
  });
  const [
    ,
    {
      isLoading: timelinesLoading,
      isUninitialized: timelinesUninitialized,
      data: timelineData,
      error: timelineError,
    },
  ] = usePermitTimelineMutation({
    fixedCacheKey: PERMIT_TIMELINE_CACHE_KEY,
  });

  const mutationStates = {
    zoning: {
      isLoading: zoningLoading,
      isUninitialized: zoningUninitialized,
      data: zoningData,
      error: zoningError,
    },
    address: {
      isLoading: addressLoading,
      isUninitialized: addressUninitialized,
      data: addressData,
      error: addressError,
    },
    siteInfo: {
      isLoading: siteInfoLoading,
      isUninitialized: siteInfoUninitialized,
      data: siteInfoData,
      error: siteInfoError,
    },
    timeline: {
      isLoading: timelinesLoading,
      isUninitialized: timelinesUninitialized,
      data: timelineData,
      error: timelineError,
    },
  } satisfies {
    [K in MutationType]: {
      isLoading: boolean;
      isUninitialized: boolean;
      data: MutationDataMap[K] | undefined;
      error: any;
    };
  };

  // Calculate aggregate loading and uninitialized states
  const isLoading = mutations.some((key) => mutationStates[key].isLoading);

  // isUninitialized should be true if ALL mutations are still in their initial state (true)
  const isUninitialized = mutations
    .filter((key) => key !== "address")
    .every((key) => mutationStates[key].isUninitialized);

  // We should use mock data in development mode when all mutations are in their initial state
  const shouldUseMockData = isDevelopment && isUninitialized;

  // Merge real data with mock data in development
  const finalMutationStates = shouldUseMockData
    ? mockMutationStates
    : mutationStates;

  const finalIsUninitialized = shouldUseMockData ? false : isUninitialized;

  const isError = mutations.some((key) => mutationStates[key].error);

  const errors = mutations
    .filter((key) => mutationStates[key].error)
    .map((key) => ({
      type: key,
      error: mutationStates[key].error,
    }));

  return {
    isLoading,
    isUninitialized: finalIsUninitialized,
    isError,
    errors,
    mutations: finalMutationStates,
  };
}
