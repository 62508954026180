import React, { useState, useRef, useId } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  type?: "text" | "email" | "password";
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  error?: string;
  helper?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  id?: string;
  inputMaxWidth?: "fit" | "full" | number;
  counter?: { current: number; max: number };
  optional?: boolean;
  removeFooter?: boolean;
  variant?: "default" | "hero";
  labelPosition?: "top" | "inline" | "left";
  join?: "left" | "right";
};

const Input = ({
  type = "text",
  label,
  placeholder = "",
  value,
  onChange,
  onBlur,
  onFocus,
  className,
  onKeyDown,
  inputClassName,
  labelClassName,
  error,
  labelPosition = "top",
  helper,
  id,
  inputMaxWidth = 420,
  counter,
  isDisabled,
  optional,
  isReadOnly,
  join,
  variant = "default",
  removeFooter,
}: Props) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const joinDirection = join;
  const variantType = variant;

  const generatedId = useId();
  const inputId = id ?? generatedId;

  const handleInputMaxWidth = (() => {
    if (typeof inputMaxWidth === "number") return `${inputMaxWidth}px`;

    switch (inputMaxWidth) {
      case "fit":
        return "fit-content";
      case "full":
        return `100%`;
      default:
        return "auto";
    }
  })();

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  const getInputValue = () => {
    return value !== undefined ? value : inputRef.current?.value || "";
  };

  return (
    <div
      className={twMerge(
        "flex flex-col",
        labelPosition === "left" && "sm:flex-row sm:justify-between sm:gap-4",
        className,
      )}
    >
      {labelPosition !== "inline" && (
        <label
          style={
            labelPosition === "top"
              ? {
                  maxWidth: handleInputMaxWidth,
                  width: "100%",
                  minWidth: 120,
                }
              : { minWidth: 120 }
          }
          htmlFor={inputId}
          className={twMerge(
            "body-font my-2 line-clamp-1 h-fit break-all",
            inputMaxWidth === "full" && "basis-1/3",
            typeof inputMaxWidth === "number" && "basis-full",
            labelClassName,
          )}
        >
          <div className="relative z-10 flex bg-transparent leading-none">
            <p className="line-clamp-1 break-all">{label}</p>
            <p className="w-fit shrink-0">{optional ? "*" : ""}</p>
          </div>
        </label>
      )}

      <div
        className={twMerge(
          "relative shrink-0 basis-2/3",
          typeof inputMaxWidth === "number" && "basis-full",
        )}
        style={{
          maxWidth: handleInputMaxWidth,
          width: "100%",
          minWidth: 120,
        }}
      >
        <div className="relative">
          {labelPosition === "inline" && (
            <label
              htmlFor={inputId}
              className={twMerge(
                "body-font absolute left-3 top-1/2 z-10 h-fit max-w-[85%] -translate-y-[50%] cursor-text px-1 text-stone-500 transition-all duration-200",

                variantType === "hero" && "left-4",

                focused || getInputValue()
                  ? "top-[-8px] -translate-y-0 text-xs text-stone-950"
                  : "pb-[1px]",

                (focused || getInputValue()) &&
                  variantType === "hero" &&
                  "left-7",

                joinDirection === "left" && "left-2",
                labelClassName,
              )}
            >
              {/* Label Text */}
              <div className="bg-transdivarent relative z-10 flex">
                <p className="line-clamp-1 break-all">{label}</p>
                <p className="w-fit shrink-0">{optional ? "*" : ""}</p>
              </div>

              {/* Bottom Background Block */}
              {(focused || getInputValue()) && (
                <span
                  className={twMerge(
                    "absolute bottom-0 left-0 z-0 h-1/2 w-full bg-stone-100",
                    isReadOnly && "bg-stone-200",
                  )}
                />
              )}
            </label>
          )}
          <input
            id={inputId}
            type={type}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : () => {})}
            placeholder={labelPosition === "inline" ? undefined : placeholder}
            value={value}
            readOnly={isReadOnly}
            ref={inputRef}
            onChange={onChange}
            tabIndex={isDisabled || isReadOnly ? -1 : undefined}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={twMerge(
              "body-font block h-12 w-full rounded-lg border-[1px] border-stone-200 bg-stone-100 px-4 placeholder:text-stone-500 focus:outline-none focus:ring-1 focus:ring-transparent disabled:cursor-not-allowed disabled:opacity-75",
              isReadOnly
                ? "cursor-default read-only:bg-stone-200 enabled:focus:border-transparent"
                : "transition-colors duration-300 enabled:hover:border-stone-400 enabled:focus:border-rf-medium-blue",
              variantType === "hero" &&
                "h-[60px] rounded-[30px] border-transparent px-5",
              joinDirection === "left" && "rounded-l-none pl-3",
              joinDirection === "right" && "rounded-r-none pr-3",
              error &&
                "border-rf-vibrant-red enabled:hover:border-rf-vibrant-red enabled:focus:border-rf-vibrant-red",
              inputClassName,
            )}
          />
        </div>
        {!removeFooter && (
          <div className="desc-font flex max-w-full justify-between gap-2">
            {error && (
              <p className="line-clamp-1 break-all text-rf-vibrant-red">
                {error}
              </p>
            )}

            {helper && !error && <p className="text-stone-700">{helper}</p>}

            {!helper && !error && <p className="">{<span>&nbsp;</span>}</p>}

            {counter && (
              <p className="text-stone-700">{`${counter?.current}/${counter?.max}`}</p>
            )}
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default Input;
