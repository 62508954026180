import { DateTime } from 'luxon';

/**
 * Format a date to "Mar 15, 2024 2:54 PM"
 * @example formatDateTime("2024-03-15T14:54:00Z") => "Mar 15, 2024 2:54 PM"
 */
export const formatDateTime = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

/**
 * Format a date to "3/15/24, 2:54 PM" (short format)
 * @example formatShortDateTime("2024-03-15T14:54:00Z") => "3/15/24, 2:54 PM"
 */
export const formatShortDateTime = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT);
};

/**
 * Format time only to "2:54 PM"
 * @example formatTimeOnly("2024-03-15T14:54:00Z") => "2:54 PM"
 */
export const formatTimeOnly = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.TIME_SIMPLE);
};

/**
 * Format date only to "Mar 15, 2024"
 * @example formatDateOnly("2024-03-15T14:54:00Z") => "Mar 15, 2024"
 */
export const formatDateOnly = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_FULL);
};

/**
 * Get relative time like "2 hours ago" or "in 3 days"
 * @example formatRelativeTime("2024-03-15T14:54:00Z") => "2 hours ago"
 */
export const formatRelativeTime = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toRelative();
};

/**
 * Format date to calendar format like "Yesterday at 2:54 PM" or "Last Monday at 2:54 PM"
 * @example formatCalendarTime("2024-03-15T14:54:00Z") => "Yesterday at 2:54 PM"
 */
export const formatCalendarTime = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toRelativeCalendar({ unit: 'days' });
};

/**
 * Check if a date is today
 * @example isToday("2024-03-15T14:54:00Z") => true/false
 */
export const isToday = (timestamp: string) => {
  if (!timestamp) return false;
  return DateTime.fromISO(timestamp).hasSame(DateTime.now(), 'day');
};

/**
 * Check if a date is in the past
 * @example isPast("2024-03-15T14:54:00Z") => true/false
 */
export const isPast = (timestamp: string) => {
  if (!timestamp) return false;
  return DateTime.fromISO(timestamp) < DateTime.now();
};

/**
 * Format a duration in seconds to "2h 30m" or "30m 15s"
 * @example formatDuration(3600) => "1h"
 * @example formatDuration(5400) => "1h 30m"
 */
export const formatDuration = (seconds: number) => {
  if (!seconds) return '';
  const dur = DateTime.now().plus({ seconds }).diff(DateTime.now());
  return dur.toHuman({ maximumFractionDigits: 0 });
};

/**
 * Get start of day for a given timestamp
 * @example getStartOfDay("2024-03-15T14:54:00Z") => DateTime object for 2024-03-15T00:00:00
 */
export const getStartOfDay = (timestamp: string) => {
  if (!timestamp) return null;
  return DateTime.fromISO(timestamp).startOf('day');
};

/**
 * Format a date for input[type="datetime-local"]
 * @example formatForDateTimeInput("2024-03-15T14:54:00Z") => "2024-03-15T14:54"
 */
export const formatForDateTimeInput = (timestamp: string) => {
  if (!timestamp) return '';
  return DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd'T'HH:mm");
};

/**
 * Get date ranges like "Mar 15 - Mar 20, 2024" or "Mar 15 - Apr 2, 2024"
 * @example formatDateRange("2024-03-15T14:54:00Z", "2024-03-20T14:54:00Z") 
 *         => "Mar 15 - Mar 20, 2024"
 */
export const formatDateRange = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return '';
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  
  if (start.hasSame(end, 'year') && start.hasSame(end, 'month')) {
    return `${start.toFormat('MMM d')} - ${end.toFormat('d, yyyy')}`;
  } else if (start.hasSame(end, 'year')) {
    return `${start.toFormat('MMM d')} - ${end.toFormat('MMM d, yyyy')}`;
  }
  return `${start.toFormat('MMM d, yyyy')} - ${end.toFormat('MMM d, yyyy')}`;
};

/**
 * Parse any reasonable date string into a consistent format
 * @example parseFlexibleDate("2024-03-15") => DateTime object
 * @example parseFlexibleDate("03/15/2024") => DateTime object
 * @example parseFlexibleDate("March 15, 2024") => DateTime object
 */
export const parseFlexibleDate = (dateString: string) => {
  if (!dateString) return null;
  return DateTime.fromISO(dateString) || 
         DateTime.fromFormat(dateString, 'MM/dd/yyyy') ||
         DateTime.fromFormat(dateString, 'MMMM d, yyyy');
};