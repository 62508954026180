import { AddressLookupResponse } from "../../../api";
import { ParsedAnnotation } from "../../../components/Answer";
import { parseAnswerToHtml } from "../../../components/Answer/AnswerParser";
import { isValidJson } from "../../../helpers/stringUtils";
import { CitationClickParams } from "../../../components/Answer/AnswerParser";

// New interface for the return type
export interface AnswerElements {
  parsedAnswerElements: {
    citations: string[];
    answerElements: JSX.Element[];
  };
  sources: ParsedAnnotation[];
  additionalSources: ParsedAnnotation[];
}

export function answerToElements(
  isLoading: boolean,
  messageContent: string,
  navigateToCitation: (params: CitationClickParams) => void,
): AnswerElements {
  let addressLookupResp: AddressLookupResponse | undefined;
  let parsedAnswerElementsResult: {
    citations: string[];
    answerElements: JSX.Element[];
  } = { citations: [], answerElements: [] }; // Initialize with default values
  let sourcesResult: ParsedAnnotation[] = [];
  let additionalSourcesResult: ParsedAnnotation[] = [];

  if (!isLoading) {
    try {
      // Only try to parse if it's valid JSON
      if (isValidJson(messageContent)) {
        const addressLookupResp: AddressLookupResponse =
          JSON.parse(messageContent);
        // Handle the answer parsing
        const answer = addressLookupResp.answer;
        const answerContent =
          typeof answer === "object" ? JSON.stringify(answer) : answer;

        parsedAnswerElementsResult = parseAnswerToHtml({
          answer: answerContent,
          isStreaming: false,
          onCitationClicked: navigateToCitation,
        });

        // Parse sources if they exist
        if (Array.isArray(addressLookupResp.sources)) {
          sourcesResult = addressLookupResp.sources
            .map((source) => {
              try {
                return new ParsedAnnotation(source);
              } catch (e) {
                console.error("Unable to parse annotation:", source);
                return null;
              }
            })
            .filter((source): source is ParsedAnnotation => source !== null);
        }

        // Parse additional sources if they exist
        if (Array.isArray(addressLookupResp.additional_sources)) {
          additionalSourcesResult = addressLookupResp.additional_sources
            .map((source) => {
              try {
                return new ParsedAnnotation(source);
              } catch (e) {
                console.error("Unable to parse annotation:", source);
                return null;
              }
            })
            .filter((source): source is ParsedAnnotation => source !== null);
        }
      } else {
        // If not valid JSON, treat as plain text
        parsedAnswerElementsResult = parseAnswerToHtml({
          answer: messageContent,
          isStreaming: false,
          onCitationClicked: navigateToCitation,
        });
      }
    } catch (e) {
      console.error("Error parsing message content:", e);
      // Handle the error, maybe log it
      parsedAnswerElementsResult = parseAnswerToHtml({
        answer:
          typeof messageContent === "string"
            ? messageContent
            : JSON.stringify(messageContent, null, 2),
        isStreaming: false,
        onCitationClicked: navigateToCitation,
      });
    }
  } else {
    parsedAnswerElementsResult = parseAnswerToHtml({
      answer: "",
      isStreaming: false,
      onCitationClicked: navigateToCitation,
    });
  }

  return {
    parsedAnswerElements: parsedAnswerElementsResult,
    sources: sourcesResult,
    additionalSources: additionalSourcesResult,
  };
}
