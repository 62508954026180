import { MotionInfo } from "../../../api";
import { twMerge } from "tailwind-merge";
import { useAppDispatch } from "../../../app/hooks";
import { openModal } from "../../modalManager/modalSlice";

interface Props {
  motion: MotionInfo;
  className?: string;
}

export const CouncilMotionCard = ({ motion, className }: Props) => {
  const dispatch = useAppDispatch();

  const openMotionModal = () => {
    dispatch(
      openModal({
        type: "MotionsViewer",
        props: { motion },
        title: "Motion",
      }),
    );
  };

  return (
    <>
      <div
        className={twMerge(
          "cursor-pointer rounded-lg border-[2px] border-white bg-white px-[20px] py-4 shadow duration-300 hover:border-rf-light-beige",
          className,
        )}
      >
        <a
          href={motion.url}
          target="_blank"
          rel="noopener noreferrer"
          className="link mb-1.5 line-clamp-2"
        >
          {motion.title_summary}
        </a>
        <div onClick={openMotionModal}>
          <p className="desc-font line-clamp-4">{motion.description}</p>
          <div className="flex-inline flex items-center justify-between gap-x-2">
            <p className="citation-font mt-1.5 line-clamp-4">
              {motion.acting_body}
            </p>
            {motion.status === "Open" && (
              <p className="desc-font rounded-lg bg-green-400 px-4 py-1 text-white">
                Open
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
