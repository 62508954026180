import { createApi } from "@reduxjs/toolkit/query/react";
import { supabase } from "../../api/db";
import type { StoredAddress } from "../../api/userConfig";
import {
  addressSearchApi,
  GetUserFilesParamsType,
} from "./addressSearchSectionApiSlice";
import { baseQuery } from "../../app/globals/baseQuery";

import {
  ChatAppRequest,
  PermitTimelineRequest,
  SiteInfoRequest,
  SiteInfoResponse,
  ChatAppResponse,
  PermitTimelineResponse,
} from "../../api";

type ZoningUseClassificationPayloadType = {
  results: { choices: { message: { content: string } }[] }[];
  titles?: string[];
};

export type ZoningUseClassificationTransformedPayloadType = {
  title?: string;
  preview?: string;
  answer?: { [key: string]: string | undefined };
  sources?: string[];
  additional_sources?: string[];
  issue?: string;
};

export const ZONING_USE_CLASSIFICATION_CACHE_KEY = "zoningChecklistCacheKey";
export const ADDRESS_SEARCH_CACHE_KEY = "addressSearchCacheKey";
export const SITE_INFO_CACHE_KEY = "siteInfoCacheKey";
export const PERMIT_TIMELINE_CACHE_KEY = "permitTimelineCacheKey";

export const addressSearchMutationsApi = createApi({
  reducerPath: "addressSearchMutationsApi",

  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createAddress: builder.mutation<
      StoredAddress,
      {
        address: string;
        user_id: string;
        lng: number;
        lat: number;
      }
    >({
      async queryFn(newAddress, { dispatch }) {
        try {
          const { data, error } = await supabase
            .from("stored_addresses")
            .insert(newAddress)
            .single();

          if (error) {
            console.error("Error creating address:", error);
            return { error: { status: 500, statusText: error.message } };
          }

          dispatch(addressSearchApi.util.invalidateTags(["StoredAddresses"]));
          return { data };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
    }),

    deleteAddress: builder.mutation<
      boolean,
      { addressId: number; userId: string }
    >({
      async queryFn({ addressId }) {
        try {
          const { error } = await supabase
            .from("stored_addresses")
            .delete()
            .eq("id", addressId);

          if (error) {
            console.error("Error deleting address:", error);
            return { error: { status: 500, statusText: error.message } };
          }

          return { data: true };
        } catch (err: unknown) {
          return {
            error: {
              status: "CUSTOM_ERROR",
              error:
                (err instanceof Error ? err.message : "Unknown error") ||
                "Unknown error occurred",
              data: undefined,
            },
          };
        }
      },
      async onQueryStarted(
        { addressId, userId },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          addressSearchApi.util.updateQueryData(
            "fetchUserAddresses",
            userId,
            (draft) => {
              return draft.filter((address) => address.id !== addressId);
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),

    getZoningUseClassification: builder.mutation<
      ZoningUseClassificationTransformedPayloadType[],
      {
        coords: [number, number];
        location: string;
        checklistId: number;
        userFilenames?: string[];
      }
    >({
      query: ({ coords, location, checklistId, userFilenames }) => ({
        url: "/formatted_answer/checklist",
        method: "POST",
        body: {
          context: {
            overrides: {
              address_coordinates: coords,
            },
          },
          location: location,
          checklist_id: checklistId,
          user_filenames: userFilenames,
        },
      }),
      // Add transformResponse if you need to modify the API response
      transformResponse: (response: ZoningUseClassificationPayloadType) => {
        const answers = response.results
          .map((result, index) => {
            const jsonString = result.choices[0].message.content;
            const parsedAnswer: {
              preview?: string;
              answer?:
                | {
                    [key: string]: string;
                  }
                | string;
              sources?: string[];
              additional_sources?: string[];
              issue?: string;
            } =
              jsonString && jsonString.length > 0
                ? JSON.parse(jsonString)
                : null;

            if (!parsedAnswer) return undefined;

            const title = response?.titles?.[index] ?? "";
            const answer =
              parsedAnswer.answer && typeof parsedAnswer.answer === "object"
                ? parsedAnswer.answer
                : {};

            const transformed: ZoningUseClassificationTransformedPayloadType = {
              title: title,
              preview: parsedAnswer.preview ?? "",
              answer: answer,
              sources: parsedAnswer.sources ?? [],
              additional_sources: parsedAnswer.additional_sources ?? [],
              issue: parsedAnswer.issue ?? "",
            };
            return transformed;
          })
          .filter(Boolean) as ZoningUseClassificationTransformedPayloadType[];
        return answers;
      },
    }),
    addressSearch: builder.mutation<ChatAppResponse, ChatAppRequest>({
      query: (request) => ({
        url: "/address",
        method: "POST",
        body: request,
      }),
    }),

    siteInfo: builder.mutation<SiteInfoResponse, SiteInfoRequest>({
      query: (request) => ({
        url: "/site",
        method: "POST",
        body: request,
      }),
    }),

    adminFileUpload: builder.mutation<
      { message?: string },
      {
        file: File;
      }
    >({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: "/document/upload",
          method: "POST",
          body: formData,
          // Explicitly set content type to undefined to let browser set correct multipart boundary
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(addressSearchApi.util.invalidateTags(["UserFiles"]));
        } catch {}
      },
    }),

    deleteFile: builder.mutation<
      { success: boolean },
      { filename: string; cacheKey: GetUserFilesParamsType }
    >({
      query: ({ filename }) => ({
        url: "document/delete_file",
        method: "POST",
        body: { filename },
      }),
      async onQueryStarted(
        { filename, cacheKey },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          addressSearchApi.util.updateQueryData(
            "getUserFiles",
            cacheKey,
            (draft) => {
              const fileIndex = draft.findIndex((file) => file.id === filename);
              if (fileIndex !== -1) {
                draft.splice(fileIndex, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    permitTimeline: builder.mutation<
      PermitTimelineResponse,
      PermitTimelineRequest
    >({
      query: (request) => ({
        url: "/permits/timeline",
        method: "POST",
        body: request,
      }),
    }),
  }),
});

export const {
  useCreateAddressMutation,
  useDeleteFileMutation,
  useDeleteAddressMutation,
  useGetZoningUseClassificationMutation,
  useAddressSearchMutation,
  useSiteInfoMutation,
  useAdminFileUploadMutation,
  usePermitTimelineMutation,
} = addressSearchMutationsApi;
