import {Calendar20Filled, Clock20Filled } from '@fluentui/react-icons';
import React from 'react';

interface TimingInfoProps {
  duration: number;
  timestamp: number;
}

export function TimingInfo({ duration, timestamp }: TimingInfoProps) {
  // Convert milliseconds to seconds and format
  const durationS = (duration % 60).toFixed();
  const durationM = (duration / 60).toFixed(0);

  // Parse timestamp and handle potential invalid dates
  const formattedDate = (() => {
    try {
      return new Date(timestamp).toLocaleString();
    } catch (e) {
      return timestamp;
    }
  })();

  return (
    <div className="flex gap-6 mb-8 text-gray-600">
      <div className="flex items-center">
        <Clock20Filled className="mr-2" />
        <span>Duration: {durationM}m {durationS}s</span>
      </div>
      <div className="flex items-center">
        <Calendar20Filled className="mr-2" />
        <span>Timestamp: {formattedDate}</span>
      </div>
    </div>
  );
}
