// Validation function for coordinates



export const isValidCoordinates = (
  coordinates: { lat: number; lng: number } | undefined,
) => {
  if (!coordinates) return false;

  const { lat, lng } = coordinates;
  const isValidLat = lat >= -90 && lat <= 90;
  const isValidLng = lng >= -180 && lng <= 180;

  return isValidLat && isValidLng;
};
