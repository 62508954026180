import {
  ArrowSortUpRegular,
  ArrowClockwiseRegular,
} from "@fluentui/react-icons";
import { useAppSelector } from "../../../app/hooks";
import HeroButton from "../../../components/ui/Buttons/HeroButton";
import useSearchMutationState from "../hooks/useSearchMutationState";
import useIsDemo from "../../../hooks/useIsDemo";

type Props = {
  address: string | undefined;
  coordinates: { lat: number; lng: number } | undefined;
};

export default function SubmitButton({ address, coordinates }: Props) {
  const isDemo = useIsDemo();

  const { isLoading: isDemoLoading } = useAppSelector((state) => state.demo);

  const {
    isLoading: isMutationLoading,
    isUninitialized: isMutationUnInitialized,
  } = useSearchMutationState();

  const isUninitialized = isMutationUnInitialized;

  const isLoading = isMutationLoading || (isDemo && isDemoLoading);

  return (
    <HeroButton
      type="submit"
      isLoading={isLoading}
      variant="hero"
      isDisabled={
        isLoading || !address || address?.length === 0 || !coordinates
      }
      label={!isUninitialized ? "Regenerate Report" : "Generate Report"}
      iconRight={{
        icon: !isUninitialized ? (
          <ArrowClockwiseRegular />
        ) : (
          <ArrowSortUpRegular />
        ),
      }}
      maxWidth={"full"}
    />
  );
}
