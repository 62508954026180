import DropZone from "../../../../components/DropZone/DropZone";
import { twMerge } from "tailwind-merge";
import FilesDisplay from "./FilesDisplay";
import { FilesDisplayItemType } from "./FilesDisplay";

type Props = {
  pdfs: File[];
  handleFileSelect: (files: FileList) => void;
  handleRemoveFile: (index: number) => void;
  isLoading: boolean;
};

export default function Upload({
  pdfs,
  handleFileSelect,
  handleRemoveFile,
  isLoading,
}: Props) {
  const formattedPdfs: FilesDisplayItemType[] = pdfs
    ? pdfs.map((pdf) => ({
        name: pdf.name,
        descs: [`${(pdf.size / 1024 / 1024).toFixed(2)} MB`],
      }))
    : [];

  return (
    <div className="flex min-h-0 flex-1 grow flex-col gap-3">
      {pdfs.length > 0 && (
        <FilesDisplay
          items={formattedPdfs}
          onClick={handleRemoveFile}
          isDisabled={isLoading}
          className="min-h-0 flex-1"
        />
      )}

      <div className={twMerge("shrink-0", pdfs.length === 0 && "grow")}>
        <DropZone
          skipDuplicates={false}
          onFileSelect={handleFileSelect}
          accept={"pdf"}
          isDisabled={isLoading}
          multiple={true}
        />
      </div>
    </div>
  );
}
