import { isValidJson } from "../../helpers/stringUtils";

type HtmlParsedAnswer = {
  answerElements: JSX.Element[];
  citations: string[];
};

export type CitationClickParams = {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>;
  path: string;
};

type Props = {
  answer: string;
  isStreaming: boolean;
  onCitationClicked: (params: CitationClickParams) => void;
};

export function parseAnswerToHtml({
  answer,
  isStreaming,
  onCitationClicked,
}: Props): HtmlParsedAnswer {
  if (!answer) {
    return {
      answerElements: [],
      citations: [],
    };
  }

  if (isValidJson(answer)) {
    try {
      const answerJson = JSON.parse(answer);
      answer = JSON.stringify(answerJson, null, 2);
    } catch (error) {
      // Should never reach here due to validation
      console.error("JSON parse error:", error);
    }
  }
  const citations: string[] = [];
  const parsedAnswer = answer.trim();

  const parts = parsedAnswer.split(/(\[\[[^\]]+\]\]|\n)/g);

  const fragments: JSX.Element[] = parts.map((part, index) => {
    if (part === "\n") {
      return <br key={`br-${index}`} />;
    }
    if (index % 2 === 0) {
      return <span key={`pt-${index}`}>{part}</span>;
    } else {
      let citationIndex: number;
      if (citations.indexOf(part) !== -1) {
        citationIndex = citations.indexOf(part) + 1;
      } else {
        citations.push(part);
        citationIndex = citations.length;
      }

      const path = part;

      return (
        <a
          key={`cit-${index}`}
          className="supContainer"
          title={part}
          onClick={(e) => onCitationClicked({ event: e, path: path })}
        >
          <sup>{citationIndex}</sup>
        </a>
      );
    }
  });
  return {
    answerElements: fragments,
    citations,
  };
}
