import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export type BreakpointKey = keyof typeof BREAKPOINTS;

interface BreakpointState {
  width: number;
  breakpoints: {
    [K in BreakpointKey]: boolean;
  };
}

const calculateBreakpoints = (
  width: number,
): BreakpointState["breakpoints"] => ({
  sm: width >= BREAKPOINTS.sm,
  md: width >= BREAKPOINTS.md,
  lg: width >= BREAKPOINTS.lg,
  xl: width >= BREAKPOINTS.xl,
  "2xl": width >= BREAKPOINTS["2xl"],
});

const initialState: BreakpointState = {
  width: window.innerWidth,
  breakpoints: calculateBreakpoints(window.innerWidth),
};

const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
      state.breakpoints = calculateBreakpoints(action.payload);
    },
  },
  selectors: {
    selectIsAbove: (state, breakpoint: BreakpointKey) =>
      state.width >= BREAKPOINTS[breakpoint],
    selectIsBelow: (state, breakpoint: BreakpointKey) =>
      state.width < BREAKPOINTS[breakpoint],
  },
});

export const { setScreenWidth } = screenSlice.actions;
export default screenSlice.reducer;

export const useBreakpoint = (breakpoint: BreakpointKey) => {
  return useAppSelector((state) => state.screen.breakpoints[breakpoint]);
};

export const useIsAbove = (breakpoint: BreakpointKey) => {
  return useAppSelector(
    (state) => state.screen.width >= BREAKPOINTS[breakpoint],
  );
};

export const useIsBelow = (breakpoint: BreakpointKey) => {
  return useAppSelector(
    (state) => state.screen.width < BREAKPOINTS[breakpoint],
  );
};
