export default function SearchError() {
  return (
    <div className="flex h-full flex-col justify-center gap-4 text-center">
      <p className="text-rf-vibrant-red">
        The address you entered is currently outside our supported search area,
        which only includes the County Of San Diego and incorporated City Of Los
        Angeles.
      </p>

      <p className="">
        We're continuously expanding our coverage. Future updates will include
        more areas.
      </p>
    </div>
  );
}
