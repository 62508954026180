import { twMerge } from "tailwind-merge";
import { SectionInfoType } from "../../../global/types";
import { useActiveSection } from "./useActiveSection";

type Props = {
  sections: SectionInfoType[];
  className?: string;
  onClick?: () => void;
};

export default function SideNav({ sections, className, onClick }: Props) {
  const activeSectionId = useActiveSection(sections);

  const handleClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 60;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    if (onClick) onClick();
  };

  return (
    <ul
      className={twMerge(
        "flex w-full max-w-[250px] list-disc flex-col gap-2 pl-5",
        className,
      )}
    >
      {sections.map((section) => (
        <li key={section.id} className="body-font">
          <button
            className={twMerge(
              "block w-full text-left text-stone-900 duration-300 hover:underline",
              activeSectionId === section.id && "text-rf-deep-blue",
            )}
            onClick={() => handleClick(section.id)}
          >
            {section.title}
          </button>
        </li>
      ))}
    </ul>
  );
}
