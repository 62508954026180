import React, { useId, useState } from "react";
import { twMerge } from "tailwind-merge";
import Input from "./Input";
import { Dismiss16Filled } from "@fluentui/react-icons";

type Props = {
  label: string;
  placeholder?: string;
  isDisabled?: boolean;
  onClear?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  maxWidth?: "fit" | "full" | number;
  labelPosition?: "left" | "top" | "inline";
  variant?: "default" | "hero";
  inputClassName?: string;
  value: string;
};

export default function SearchableInput({
  label,
  labelPosition = "top",
  placeholder,
  isDisabled,
  maxWidth = 420,
  variant = "default",
  onClear,
  onBlur,
  onFocus,
  onChange,
  inputClassName,
  value,
}: Props) {
  const inputId = useId();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  const handleClear = () => {
    if (onClear) onClear();
    if (onChange) onChange("");
  };

  return (
    <div
      className="relative"
      style={{
        maxWidth: maxWidth,
        width: "100%",
        minWidth: 120,
      }}
    >
      <div className="relative">
        <Input
          isDisabled={isDisabled}
          label={label}
          labelPosition={labelPosition}
          id={inputId}
          removeFooter={true}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleInputChange}
          variant={variant}
          placeholder={placeholder}
          inputMaxWidth={maxWidth}
          inputClassName={twMerge("pr-12", inputClassName)}
        />
        {value && (
          <button
            type="button"
            onClick={handleClear}
            disabled={isDisabled}
            className={twMerge(
              "absolute right-3 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center justify-center rounded-full transition-colors",
              "hover:bg-stone-200 active:bg-stone-300 disabled:pointer-events-none disabled:opacity-50",
            )}
          >
            <Dismiss16Filled
              className={twMerge(
                "h-4 w-4 shrink-0 leading-none text-stone-800",
                variant === "hero" && "h-5 w-5",
              )}
            />
          </button>
        )}
      </div>
    </div>
  );
}
