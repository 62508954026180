import React from "react";
import {
  useGetUserFilesQuery,
  GetUserFilesParamsType,
} from "../../../AddressSearchSection/addressSearchSectionApiSlice";
import { formatRelativeTime } from "../../../../helpers/dateAndTimeHelpers";
import FilesDisplay, { FilesDisplayItemType } from "./FilesDisplay";
import Spinner from "../../../../components/ui/Loaders/Spinner";
import { useDeleteFileMutation } from "../../../AddressSearchSection/AddressSearchSectionMutationsApiSlice";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import useIsDemo from "../../../../hooks/useIsDemo";
import { removeFakeDocument } from "../../../_Demo/demoSlice";

const getUserFilesParams: GetUserFilesParamsType = {
  category: "get_user_documents",
};

export default function Files() {
  const dispatch = useAppDispatch();
  const isDemo = useIsDemo();
  const { fakeDocuments } = useAppSelector((state) => state.demo);

  const {
    data: documentsData,
    isFetching,
    isError,
    error,
  } = useGetUserFilesQuery(getUserFilesParams, { skip: isDemo });

  const documents = isDemo ? fakeDocuments : documentsData;

  const [deleteFile] = useDeleteFileMutation();

  const formattedPdfs: FilesDisplayItemType[] = documents
    ? documents.map((document) => ({
        name: document.name ?? document.id,
        descs: [document.id, formatRelativeTime(document.created_at) ?? ""],
        id: document.id,
      }))
    : [];

  const handleDelete = async (index: number) => {
    const file = formattedPdfs[index];

    if (!file || !file?.name) return;

    try {
      if (isDemo) {
        dispatch(removeFakeDocument(file.name));
      } else {
        await deleteFile({
          filename: file.id ?? "",
          cacheKey: getUserFilesParams,
        }).unwrap();
      }
    } catch (error) {
      console.error("Delete failed:", error);
    }
  };

  if (isFetching)
    return (
      <div className="flex h-full justify-center">
        <Spinner />
      </div>
    );

  if (error || !documents)
    return (
      <div className="h-full">
        <p className="body-font">{JSON.stringify(error)}</p>
      </div>
    );

  if (formattedPdfs.length == 0 && !isFetching)
    return (
      <div className="flex min-h-0 flex-1 grow flex-col justify-center">
        <p className="body-font mx-auto max-w-sm text-center">
          Looks like you haven’t uploaded any files yet! Head over to the Files
          tab to start uploading
        </p>
      </div>
    );

  return (
    <div className="flex min-h-0 flex-1 grow flex-col gap-3">
      <FilesDisplay
        isDisabled={isFetching}
        onClick={handleDelete}
        items={formattedPdfs}
        className="min-h-0 flex-1 grow"
      />
    </div>
  );
}
