import React from "react";
import HeroButton from "../../../components/ui/Buttons/HeroButton";
import useSearchMutationState from "../hooks/useSearchMutationState";
import { DocumentMultipleRegular } from "@fluentui/react-icons";
import { openModal } from "../../modalManager/modalSlice";
import { useAppDispatch } from "../../../app/hooks";

export default function DocumentUpload() {
  const dispatch = useAppDispatch();
  const { isLoading } = useSearchMutationState();

  const isDisabled = isLoading;

  return (
    <HeroButton
      maxWidth={"full"}
      isDisabled={isDisabled}
      onClick={() =>
        dispatch(
          openModal({
            type: "BYODManager",
            props: { initialTab: "upload" },
          }),
        )
      }
      variant="hero-outline"
      label="(Optional) securely add documents"
      iconRight={{
        icon: <DocumentMultipleRegular />,
        isDisabled: isDisabled,
        ariaLabel: "Open document modal",

        onClick: () =>
          dispatch(
            openModal({
              type: "BYODManager",
              props: { initialTab: "files" },
            }),
          ),
      }}
    />
  );
}
