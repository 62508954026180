import { useEffect, useState } from "react";

/**
 * useArrowKeyNavigation
 * Custom hook that provides arrow key navigation for a list of items, with optional selection on Enter key press.
 *
 * @param isOpen - Boolean indicating if navigation should be active.
 * @param items - Array of items to navigate through.
 * @param onSelect - Callback function to call when an item is selected (Enter key).
 *
 * @returns An object containing:
 * - `focusedIndex`: Index of the currently focused item.
 * - `setFocusedIndex`: Setter for manually adjusting the focused index.
 *
 * The hook listens for "keydown" events when `isOpen` is true, enabling:
 * - "ArrowDown": Moves focus to the next item, wrapping to the first item if at the end.
 * - "ArrowUp": Moves focus to the previous item, wrapping to the last item if at the beginning.
 * - "Enter": Calls `onSelect` with the currently focused item.
 */

function useArrowKeyNavigation<T>({
  isOpen,
  items,
  onSelect,
  onExitFocus,
  onEnter,
}: {
  isOpen: boolean;
  items: T[];
  onSelect: (item: T) => void;
  onExitFocus?: () => void; // New optional callback for exiting dropdown
  onEnter?: () => void; // New optional callback for exiting dropdown
}) {
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  useEffect(() => {
    // Reset focused index when dropdown closes or items change
    if (!isOpen || items.length === 0) {
      setFocusedIndex(-1);
    }
  }, [isOpen, items]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!isOpen || items.length === 0) return;

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          event.stopPropagation();
          setFocusedIndex((prevIndex) =>
            // If at the end, wrap to the start
            prevIndex < items.length - 1 ? prevIndex + 1 : 0,
          );
          break;

        case "ArrowUp":
          event.preventDefault();
          event.stopPropagation();
          setFocusedIndex((prevIndex) => {
            if (prevIndex > 0) {
              // Move up within items
              return prevIndex - 1;
            } else if (onExitFocus) {
              // If at the top, exit to input or previous element
              onExitFocus();
              return -1;
            }
            // If no exit handler, wrap to bottom
            return items.length - 1;
          });
          break;

        case "Enter":
          event.preventDefault();
          event.stopPropagation();

          if (focusedIndex >= 0 && focusedIndex < items.length) {
            onSelect(items[focusedIndex]);
            if (onEnter) onEnter();
          }
          break;

        default:
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, items, focusedIndex, onSelect, onExitFocus]);

  return { focusedIndex, setFocusedIndex };
}

export default useArrowKeyNavigation;
