import React, { ComponentType } from "react";
import { useAppSelector } from "../../app/hooks";

import { useIsBelow } from "../../app/globals/screenSlice";
import DesktopModal from "./components/DesktopModal";
import BottomSheet from "./components/BottomSheet";

// modals
import MotionsViewer from "./modals/MotionsViewer";
import SearchError from "./modals/SearchError";
import SavedAddresses from "./modals/SavedAddresses/SavedAddresses";
import BYODManager from "./modals/BYODManager/BYODManager";
import InfoSection from "./modals/InfoSection";
import ListDetailsCategorySelector from "./modals/ListDetailsCategorySelector";

const MODAL_COMPONENTS: { [key: string]: ComponentType<any> } = {
  MotionsViewer,
  SearchError,
  SavedAddresses,
  BYODManager,
  InfoSection,
  ListDetailsCategorySelector,
};

export default function ModalManager() {
  const isMobile = useIsBelow("sm");

  const { isOpen, currentModal, title } = useAppSelector(
    (state) => state.modal,
  );

  if (!isOpen || !currentModal) return null;

  const SpecificModal = MODAL_COMPONENTS[currentModal.type];

  // Mobile Bottom Sheet
  if (isMobile) return <BottomSheet SpecificModal={SpecificModal} />;

  // Desktop Modal
  return <DesktopModal SpecificModal={SpecificModal} />;
}
